export const objectInfo = {
  KSampler: {
    input: {
      required: {
        model: [
          'MODEL',
          {
            tooltip: 'The model used for denoising the input latent.'
          }
        ],
        seed: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 18446744073709552000,
            tooltip: 'The random seed used for creating the noise.'
          }
        ],
        steps: [
          'INT',
          {
            default: 20,
            min: 1,
            max: 10000,
            tooltip: 'The number of steps used in the denoising process.'
          }
        ],
        cfg: [
          'FLOAT',
          {
            default: 8,
            min: 0,
            max: 100,
            step: 0.1,
            round: 0.01,
            tooltip:
              'The Classifier-Free Guidance scale balances creativity and adherence to the prompt. Higher values result in images more closely matching the prompt however too high values will negatively impact quality.'
          }
        ],
        sampler_name: [
          [
            'euler',
            'euler_cfg_pp',
            'euler_ancestral',
            'euler_ancestral_cfg_pp',
            'heun',
            'heunpp2',
            'dpm_2',
            'dpm_2_ancestral',
            'lms',
            'dpm_fast',
            'dpm_adaptive',
            'dpmpp_2s_ancestral',
            'dpmpp_2s_ancestral_cfg_pp',
            'dpmpp_sde',
            'dpmpp_sde_gpu',
            'dpmpp_2m',
            'dpmpp_2m_cfg_pp',
            'dpmpp_2m_sde',
            'dpmpp_2m_sde_gpu',
            'dpmpp_3m_sde',
            'dpmpp_3m_sde_gpu',
            'ddpm',
            'lcm',
            'ipndm',
            'ipndm_v',
            'deis',
            'ddim',
            'uni_pc',
            'uni_pc_bh2'
          ],
          {
            tooltip:
              'The algorithm used when sampling, this can affect the quality, speed, and style of the generated output.'
          }
        ],
        scheduler: [
          [
            'normal',
            'karras',
            'exponential',
            'sgm_uniform',
            'simple',
            'ddim_uniform',
            'beta',
            'linear_quadratic'
          ],
          {
            tooltip:
              'The scheduler controls how noise is gradually removed to form the image.'
          }
        ],
        positive: [
          'CONDITIONING',
          {
            tooltip:
              'The conditioning describing the attributes you want to include in the image.'
          }
        ],
        negative: [
          'CONDITIONING',
          {
            tooltip:
              'The conditioning describing the attributes you want to exclude from the image.'
          }
        ],
        latent_image: [
          'LATENT',
          {
            tooltip: 'The latent image to denoise.'
          }
        ],
        denoise: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01,
            tooltip:
              'The amount of denoising applied, lower values will maintain the structure of the initial image allowing for image to image sampling.'
          }
        ]
      }
    },
    input_order: {
      required: [
        'model',
        'seed',
        'steps',
        'cfg',
        'sampler_name',
        'scheduler',
        'positive',
        'negative',
        'latent_image',
        'denoise'
      ]
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'KSampler',
    display_name: 'KSampler',
    description:
      'Uses the provided model, positive and negative conditioning to denoise the latent image.',
    python_module: 'nodes',
    category: 'sampling',
    output_node: false,
    output_tooltips: ['The denoised latent.']
  },
  CheckpointLoaderSimple: {
    input: {
      required: {
        ckpt_name: [
          ['Realistic_Vision_V5.0.safetensors'],
          {
            tooltip: 'The name of the checkpoint (model) to load.'
          }
        ]
      }
    },
    input_order: {
      required: ['ckpt_name']
    },
    output: ['MODEL', 'CLIP', 'VAE'],
    output_is_list: [false, false, false],
    output_name: ['MODEL', 'CLIP', 'VAE'],
    name: 'CheckpointLoaderSimple',
    display_name: 'Load Checkpoint',
    description:
      'Loads a diffusion model checkpoint, diffusion models are used to denoise latents.',
    python_module: 'nodes',
    category: 'loaders',
    output_node: false,
    output_tooltips: [
      'The model used for denoising latents.',
      'The CLIP model used for encoding text prompts.',
      'The VAE model used for encoding and decoding images to and from latent space.'
    ]
  },
  CLIPTextEncode: {
    input: {
      required: {
        text: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true,
            tooltip: 'The text to be encoded.'
          }
        ],
        clip: [
          'CLIP',
          {
            tooltip: 'The CLIP model used for encoding the text.'
          }
        ]
      }
    },
    input_order: {
      required: ['text', 'clip']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'CLIPTextEncode',
    display_name: 'CLIP Text Encode (Prompt)',
    description:
      'Encodes a text prompt using a CLIP model into an embedding that can be used to guide the diffusion model towards generating specific images.',
    python_module: 'nodes',
    category: 'conditioning',
    output_node: false,
    output_tooltips: [
      'A conditioning containing the embedded text used to guide the diffusion model.'
    ]
  },
  CLIPSetLastLayer: {
    input: {
      required: {
        clip: ['CLIP'],
        stop_at_clip_layer: [
          'INT',
          {
            default: -1,
            min: -24,
            max: -1,
            step: 1
          }
        ]
      }
    },
    input_order: {
      required: ['clip', 'stop_at_clip_layer']
    },
    output: ['CLIP'],
    output_is_list: [false],
    output_name: ['CLIP'],
    name: 'CLIPSetLastLayer',
    display_name: 'CLIP Set Last Layer',
    description: '',
    python_module: 'nodes',
    category: 'conditioning',
    output_node: false
  },
  VAEDecode: {
    input: {
      required: {
        samples: [
          'LATENT',
          {
            tooltip: 'The latent to be decoded.'
          }
        ],
        vae: [
          'VAE',
          {
            tooltip: 'The VAE model used for decoding the latent.'
          }
        ]
      }
    },
    input_order: {
      required: ['samples', 'vae']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'VAEDecode',
    display_name: 'VAE Decode',
    description: 'Decodes latent images back into pixel space images.',
    python_module: 'nodes',
    category: 'latent',
    output_node: false,
    output_tooltips: ['The decoded image.']
  },
  VAEEncode: {
    input: {
      required: {
        pixels: ['IMAGE'],
        vae: ['VAE']
      }
    },
    input_order: {
      required: ['pixels', 'vae']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'VAEEncode',
    display_name: 'VAE Encode',
    description: '',
    python_module: 'nodes',
    category: 'latent',
    output_node: false
  },
  VAEEncodeForInpaint: {
    input: {
      required: {
        pixels: ['IMAGE'],
        vae: ['VAE'],
        mask: ['MASK'],
        grow_mask_by: [
          'INT',
          {
            default: 6,
            min: 0,
            max: 64,
            step: 1
          }
        ]
      }
    },
    input_order: {
      required: ['pixels', 'vae', 'mask', 'grow_mask_by']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'VAEEncodeForInpaint',
    display_name: 'VAE Encode (for Inpainting)',
    description: '',
    python_module: 'nodes',
    category: 'latent/inpaint',
    output_node: false
  },
  VAELoader: {
    input: {
      required: {
        vae_name: [[]]
      }
    },
    input_order: {
      required: ['vae_name']
    },
    output: ['VAE'],
    output_is_list: [false],
    output_name: ['VAE'],
    name: 'VAELoader',
    display_name: 'Load VAE',
    description: '',
    python_module: 'nodes',
    category: 'loaders',
    output_node: false
  },
  EmptyLatentImage: {
    input: {
      required: {
        width: [
          'INT',
          {
            default: 512,
            min: 16,
            max: 16384,
            step: 8,
            tooltip: 'The width of the latent images in pixels.'
          }
        ],
        height: [
          'INT',
          {
            default: 512,
            min: 16,
            max: 16384,
            step: 8,
            tooltip: 'The height of the latent images in pixels.'
          }
        ],
        batch_size: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096,
            tooltip: 'The number of latent images in the batch.'
          }
        ]
      }
    },
    input_order: {
      required: ['width', 'height', 'batch_size']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'EmptyLatentImage',
    display_name: 'Empty Latent Image',
    description:
      'Create a new batch of empty latent images to be denoised via sampling.',
    python_module: 'nodes',
    category: 'latent',
    output_node: false,
    output_tooltips: ['The empty latent image batch.']
  },
  LatentUpscale: {
    input: {
      required: {
        samples: ['LATENT'],
        upscale_method: [
          ['nearest-exact', 'bilinear', 'area', 'bicubic', 'bislerp']
        ],
        width: [
          'INT',
          {
            default: 512,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        height: [
          'INT',
          {
            default: 512,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        crop: [['disabled', 'center']]
      }
    },
    input_order: {
      required: ['samples', 'upscale_method', 'width', 'height', 'crop']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentUpscale',
    display_name: 'Upscale Latent',
    description: '',
    python_module: 'nodes',
    category: 'latent',
    output_node: false
  },
  LatentUpscaleBy: {
    input: {
      required: {
        samples: ['LATENT'],
        upscale_method: [
          ['nearest-exact', 'bilinear', 'area', 'bicubic', 'bislerp']
        ],
        scale_by: [
          'FLOAT',
          {
            default: 1.5,
            min: 0.01,
            max: 8,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['samples', 'upscale_method', 'scale_by']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentUpscaleBy',
    display_name: 'Upscale Latent By',
    description: '',
    python_module: 'nodes',
    category: 'latent',
    output_node: false
  },
  LatentFromBatch: {
    input: {
      required: {
        samples: ['LATENT'],
        batch_index: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 63
          }
        ],
        length: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 64
          }
        ]
      }
    },
    input_order: {
      required: ['samples', 'batch_index', 'length']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentFromBatch',
    display_name: 'Latent From Batch',
    description: '',
    python_module: 'nodes',
    category: 'latent/batch',
    output_node: false
  },
  RepeatLatentBatch: {
    input: {
      required: {
        samples: ['LATENT'],
        amount: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 64
          }
        ]
      }
    },
    input_order: {
      required: ['samples', 'amount']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'RepeatLatentBatch',
    display_name: 'Repeat Latent Batch',
    description: '',
    python_module: 'nodes',
    category: 'latent/batch',
    output_node: false
  },
  SaveImage: {
    input: {
      required: {
        images: [
          'IMAGE',
          {
            tooltip: 'The images to save.'
          }
        ],
        filename_prefix: [
          'STRING',
          {
            default: 'ComfyUI',
            tooltip:
              'The prefix for the file to save. This may include formatting information such as %date:yyyy-MM-dd% or %Empty Latent Image.width% to include values from nodes.'
          }
        ]
      },
      hidden: {
        prompt: 'PROMPT',
        extra_pnginfo: 'EXTRA_PNGINFO'
      }
    },
    input_order: {
      required: ['images', 'filename_prefix'],
      hidden: ['prompt', 'extra_pnginfo']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'SaveImage',
    display_name: 'Save Image',
    description: 'Saves the input images to your ComfyUI output directory.',
    python_module: 'nodes',
    category: 'image',
    output_node: true
  },
  PreviewImage: {
    input: {
      required: {
        images: ['IMAGE']
      },
      hidden: {
        prompt: 'PROMPT',
        extra_pnginfo: 'EXTRA_PNGINFO'
      }
    },
    input_order: {
      required: ['images'],
      hidden: ['prompt', 'extra_pnginfo']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'PreviewImage',
    display_name: 'Preview Image',
    description: 'Saves the input images to your ComfyUI output directory.',
    python_module: 'nodes',
    category: 'image',
    output_node: true
  },
  LoadImage: {
    input: {
      required: {
        image: [
          ['example.png'],
          {
            image_upload: true
          }
        ],
        upload: ['IMAGEUPLOAD']
      }
    },
    input_order: {
      required: ['image']
    },
    output: ['IMAGE', 'MASK'],
    output_is_list: [false, false],
    output_name: ['IMAGE', 'MASK'],
    name: 'LoadImage',
    display_name: 'Load Image',
    description: '',
    python_module: 'nodes',
    category: 'image',
    output_node: false
  },
  LoadImageMask: {
    input: {
      required: {
        image: [
          ['example.png'],
          {
            image_upload: true
          }
        ],
        channel: [['alpha', 'red', 'green', 'blue']],
        upload: ['IMAGEUPLOAD']
      }
    },
    input_order: {
      required: ['image', 'channel']
    },
    output: ['MASK'],
    output_is_list: [false],
    output_name: ['MASK'],
    name: 'LoadImageMask',
    display_name: 'Load Image (as Mask)',
    description: '',
    python_module: 'nodes',
    category: 'mask',
    output_node: false
  },
  ImageScale: {
    input: {
      required: {
        image: ['IMAGE'],
        upscale_method: [
          ['nearest-exact', 'bilinear', 'area', 'bicubic', 'lanczos']
        ],
        width: [
          'INT',
          {
            default: 512,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        height: [
          'INT',
          {
            default: 512,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        crop: [['disabled', 'center']]
      }
    },
    input_order: {
      required: ['image', 'upscale_method', 'width', 'height', 'crop']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'ImageScale',
    display_name: 'Upscale Image',
    description: '',
    python_module: 'nodes',
    category: 'image/upscaling',
    output_node: false
  },
  ImageScaleBy: {
    input: {
      required: {
        image: ['IMAGE'],
        upscale_method: [
          ['nearest-exact', 'bilinear', 'area', 'bicubic', 'lanczos']
        ],
        scale_by: [
          'FLOAT',
          {
            default: 1,
            min: 0.01,
            max: 8,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['image', 'upscale_method', 'scale_by']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'ImageScaleBy',
    display_name: 'Upscale Image By',
    description: '',
    python_module: 'nodes',
    category: 'image/upscaling',
    output_node: false
  },
  ImageInvert: {
    input: {
      required: {
        image: ['IMAGE']
      }
    },
    input_order: {
      required: ['image']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'ImageInvert',
    display_name: 'Invert Image',
    description: '',
    python_module: 'nodes',
    category: 'image',
    output_node: false
  },
  ImageBatch: {
    input: {
      required: {
        image1: ['IMAGE'],
        image2: ['IMAGE']
      }
    },
    input_order: {
      required: ['image1', 'image2']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'ImageBatch',
    display_name: 'Batch Images',
    description: '',
    python_module: 'nodes',
    category: 'image',
    output_node: false
  },
  ImagePadForOutpaint: {
    input: {
      required: {
        image: ['IMAGE'],
        left: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        top: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        right: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        bottom: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        feathering: [
          'INT',
          {
            default: 40,
            min: 0,
            max: 16384,
            step: 1
          }
        ]
      }
    },
    input_order: {
      required: ['image', 'left', 'top', 'right', 'bottom', 'feathering']
    },
    output: ['IMAGE', 'MASK'],
    output_is_list: [false, false],
    output_name: ['IMAGE', 'MASK'],
    name: 'ImagePadForOutpaint',
    display_name: 'Pad Image for Outpainting',
    description: '',
    python_module: 'nodes',
    category: 'image',
    output_node: false
  },
  EmptyImage: {
    input: {
      required: {
        width: [
          'INT',
          {
            default: 512,
            min: 1,
            max: 16384,
            step: 1
          }
        ],
        height: [
          'INT',
          {
            default: 512,
            min: 1,
            max: 16384,
            step: 1
          }
        ],
        batch_size: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096
          }
        ],
        color: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16777215,
            step: 1,
            display: 'color'
          }
        ]
      }
    },
    input_order: {
      required: ['width', 'height', 'batch_size', 'color']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'EmptyImage',
    display_name: 'EmptyImage',
    description: '',
    python_module: 'nodes',
    category: 'image',
    output_node: false
  },
  ConditioningAverage: {
    input: {
      required: {
        conditioning_to: ['CONDITIONING'],
        conditioning_from: ['CONDITIONING'],
        conditioning_to_strength: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'conditioning_to',
        'conditioning_from',
        'conditioning_to_strength'
      ]
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'ConditioningAverage',
    display_name: 'ConditioningAverage',
    description: '',
    python_module: 'nodes',
    category: 'conditioning',
    output_node: false
  },
  ConditioningCombine: {
    input: {
      required: {
        conditioning_1: ['CONDITIONING'],
        conditioning_2: ['CONDITIONING']
      }
    },
    input_order: {
      required: ['conditioning_1', 'conditioning_2']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'ConditioningCombine',
    display_name: 'Conditioning (Combine)',
    description: '',
    python_module: 'nodes',
    category: 'conditioning',
    output_node: false
  },
  ConditioningConcat: {
    input: {
      required: {
        conditioning_to: ['CONDITIONING'],
        conditioning_from: ['CONDITIONING']
      }
    },
    input_order: {
      required: ['conditioning_to', 'conditioning_from']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'ConditioningConcat',
    display_name: 'Conditioning (Concat)',
    description: '',
    python_module: 'nodes',
    category: 'conditioning',
    output_node: false
  },
  ConditioningSetArea: {
    input: {
      required: {
        conditioning: ['CONDITIONING'],
        width: [
          'INT',
          {
            default: 64,
            min: 64,
            max: 16384,
            step: 8
          }
        ],
        height: [
          'INT',
          {
            default: 64,
            min: 64,
            max: 16384,
            step: 8
          }
        ],
        x: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        y: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        strength: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['conditioning', 'width', 'height', 'x', 'y', 'strength']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'ConditioningSetArea',
    display_name: 'Conditioning (Set Area)',
    description: '',
    python_module: 'nodes',
    category: 'conditioning',
    output_node: false
  },
  ConditioningSetAreaPercentage: {
    input: {
      required: {
        conditioning: ['CONDITIONING'],
        width: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        height: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        x: [
          'FLOAT',
          {
            default: 0,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        y: [
          'FLOAT',
          {
            default: 0,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        strength: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['conditioning', 'width', 'height', 'x', 'y', 'strength']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'ConditioningSetAreaPercentage',
    display_name: 'Conditioning (Set Area with Percentage)',
    description: '',
    python_module: 'nodes',
    category: 'conditioning',
    output_node: false
  },
  ConditioningSetAreaStrength: {
    input: {
      required: {
        conditioning: ['CONDITIONING'],
        strength: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['conditioning', 'strength']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'ConditioningSetAreaStrength',
    display_name: 'ConditioningSetAreaStrength',
    description: '',
    python_module: 'nodes',
    category: 'conditioning',
    output_node: false
  },
  ConditioningSetMask: {
    input: {
      required: {
        conditioning: ['CONDITIONING'],
        mask: ['MASK'],
        strength: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        set_cond_area: [['default', 'mask bounds']]
      }
    },
    input_order: {
      required: ['conditioning', 'mask', 'strength', 'set_cond_area']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'ConditioningSetMask',
    display_name: 'Conditioning (Set Mask)',
    description: '',
    python_module: 'nodes',
    category: 'conditioning',
    output_node: false
  },
  KSamplerAdvanced: {
    input: {
      required: {
        model: ['MODEL'],
        add_noise: [['enable', 'disable']],
        noise_seed: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 18446744073709552000
          }
        ],
        steps: [
          'INT',
          {
            default: 20,
            min: 1,
            max: 10000
          }
        ],
        cfg: [
          'FLOAT',
          {
            default: 8,
            min: 0,
            max: 100,
            step: 0.1,
            round: 0.01
          }
        ],
        sampler_name: [
          [
            'euler',
            'euler_cfg_pp',
            'euler_ancestral',
            'euler_ancestral_cfg_pp',
            'heun',
            'heunpp2',
            'dpm_2',
            'dpm_2_ancestral',
            'lms',
            'dpm_fast',
            'dpm_adaptive',
            'dpmpp_2s_ancestral',
            'dpmpp_2s_ancestral_cfg_pp',
            'dpmpp_sde',
            'dpmpp_sde_gpu',
            'dpmpp_2m',
            'dpmpp_2m_cfg_pp',
            'dpmpp_2m_sde',
            'dpmpp_2m_sde_gpu',
            'dpmpp_3m_sde',
            'dpmpp_3m_sde_gpu',
            'ddpm',
            'lcm',
            'ipndm',
            'ipndm_v',
            'deis',
            'ddim',
            'uni_pc',
            'uni_pc_bh2'
          ]
        ],
        scheduler: [
          [
            'normal',
            'karras',
            'exponential',
            'sgm_uniform',
            'simple',
            'ddim_uniform',
            'beta',
            'linear_quadratic'
          ]
        ],
        positive: ['CONDITIONING'],
        negative: ['CONDITIONING'],
        latent_image: ['LATENT'],
        start_at_step: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 10000
          }
        ],
        end_at_step: [
          'INT',
          {
            default: 10000,
            min: 0,
            max: 10000
          }
        ],
        return_with_leftover_noise: [['disable', 'enable']]
      }
    },
    input_order: {
      required: [
        'model',
        'add_noise',
        'noise_seed',
        'steps',
        'cfg',
        'sampler_name',
        'scheduler',
        'positive',
        'negative',
        'latent_image',
        'start_at_step',
        'end_at_step',
        'return_with_leftover_noise'
      ]
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'KSamplerAdvanced',
    display_name: 'KSampler (Advanced)',
    description: '',
    python_module: 'nodes',
    category: 'sampling',
    output_node: false
  },
  SetLatentNoiseMask: {
    input: {
      required: {
        samples: ['LATENT'],
        mask: ['MASK']
      }
    },
    input_order: {
      required: ['samples', 'mask']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'SetLatentNoiseMask',
    display_name: 'Set Latent Noise Mask',
    description: '',
    python_module: 'nodes',
    category: 'latent/inpaint',
    output_node: false
  },
  LatentComposite: {
    input: {
      required: {
        samples_to: ['LATENT'],
        samples_from: ['LATENT'],
        x: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        y: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        feather: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ]
      }
    },
    input_order: {
      required: ['samples_to', 'samples_from', 'x', 'y', 'feather']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentComposite',
    display_name: 'Latent Composite',
    description: '',
    python_module: 'nodes',
    category: 'latent',
    output_node: false
  },
  LatentBlend: {
    input: {
      required: {
        samples1: ['LATENT'],
        samples2: ['LATENT'],
        blend_factor: [
          'FLOAT',
          {
            default: 0.5,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['samples1', 'samples2', 'blend_factor']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentBlend',
    display_name: 'Latent Blend',
    description: '',
    python_module: 'nodes',
    category: '_for_testing',
    output_node: false
  },
  LatentRotate: {
    input: {
      required: {
        samples: ['LATENT'],
        rotation: [['none', '90 degrees', '180 degrees', '270 degrees']]
      }
    },
    input_order: {
      required: ['samples', 'rotation']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentRotate',
    display_name: 'Rotate Latent',
    description: '',
    python_module: 'nodes',
    category: 'latent/transform',
    output_node: false
  },
  LatentFlip: {
    input: {
      required: {
        samples: ['LATENT'],
        flip_method: [['x-axis: vertically', 'y-axis: horizontally']]
      }
    },
    input_order: {
      required: ['samples', 'flip_method']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentFlip',
    display_name: 'Flip Latent',
    description: '',
    python_module: 'nodes',
    category: 'latent/transform',
    output_node: false
  },
  LatentCrop: {
    input: {
      required: {
        samples: ['LATENT'],
        width: [
          'INT',
          {
            default: 512,
            min: 64,
            max: 16384,
            step: 8
          }
        ],
        height: [
          'INT',
          {
            default: 512,
            min: 64,
            max: 16384,
            step: 8
          }
        ],
        x: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        y: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ]
      }
    },
    input_order: {
      required: ['samples', 'width', 'height', 'x', 'y']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentCrop',
    display_name: 'Crop Latent',
    description: '',
    python_module: 'nodes',
    category: 'latent/transform',
    output_node: false
  },
  LoraLoader: {
    input: {
      required: {
        model: [
          'MODEL',
          {
            tooltip: 'The diffusion model the LoRA will be applied to.'
          }
        ],
        clip: [
          'CLIP',
          {
            tooltip: 'The CLIP model the LoRA will be applied to.'
          }
        ],
        lora_name: [
          ['trapsgoonwild02.safetensors'],
          {
            tooltip: 'The name of the LoRA.'
          }
        ],
        strength_model: [
          'FLOAT',
          {
            default: 1,
            min: -100,
            max: 100,
            step: 0.01,
            tooltip:
              'How strongly to modify the diffusion model. This value can be negative.'
          }
        ],
        strength_clip: [
          'FLOAT',
          {
            default: 1,
            min: -100,
            max: 100,
            step: 0.01,
            tooltip:
              'How strongly to modify the CLIP model. This value can be negative.'
          }
        ]
      }
    },
    input_order: {
      required: [
        'model',
        'clip',
        'lora_name',
        'strength_model',
        'strength_clip'
      ]
    },
    output: ['MODEL', 'CLIP'],
    output_is_list: [false, false],
    output_name: ['MODEL', 'CLIP'],
    name: 'LoraLoader',
    display_name: 'Load LoRA',
    description:
      'LoRAs are used to modify diffusion and CLIP models, altering the way in which latents are denoised such as applying styles. Multiple LoRA nodes can be linked together.',
    python_module: 'nodes',
    category: 'loaders',
    output_node: false,
    output_tooltips: [
      'The modified diffusion model.',
      'The modified CLIP model.'
    ]
  },
  CLIPLoader: {
    input: {
      required: {
        clip_name: [[]],
        type: [
          [
            'stable_diffusion',
            'stable_cascade',
            'sd3',
            'stable_audio',
            'mochi',
            'ltxv'
          ]
        ]
      }
    },
    input_order: {
      required: ['clip_name', 'type']
    },
    output: ['CLIP'],
    output_is_list: [false],
    output_name: ['CLIP'],
    name: 'CLIPLoader',
    display_name: 'Load CLIP',
    description:
      '[Recipes]\n\nstable_diffusion: clip-l\nstable_cascade: clip-g\nsd3: t5 / clip-g / clip-l\nstable_audio: t5\nmochi: t5',
    python_module: 'nodes',
    category: 'advanced/loaders',
    output_node: false
  },
  UNETLoader: {
    input: {
      required: {
        unet_name: [[]],
        weight_dtype: [['default', 'fp8_e4m3fn', 'fp8_e4m3fn_fast', 'fp8_e5m2']]
      }
    },
    input_order: {
      required: ['unet_name', 'weight_dtype']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'UNETLoader',
    display_name: 'Load Diffusion Model',
    description: '',
    python_module: 'nodes',
    category: 'advanced/loaders',
    output_node: false
  },
  DualCLIPLoader: {
    input: {
      required: {
        clip_name1: [[]],
        clip_name2: [[]],
        type: [['sdxl', 'sd3', 'flux']]
      }
    },
    input_order: {
      required: ['clip_name1', 'clip_name2', 'type']
    },
    output: ['CLIP'],
    output_is_list: [false],
    output_name: ['CLIP'],
    name: 'DualCLIPLoader',
    display_name: 'DualCLIPLoader',
    description:
      '[Recipes]\n\nsdxl: clip-l, clip-g\nsd3: clip-l, clip-g / clip-l, t5 / clip-g, t5\nflux: clip-l, t5',
    python_module: 'nodes',
    category: 'advanced/loaders',
    output_node: false
  },
  CLIPVisionEncode: {
    input: {
      required: {
        clip_vision: ['CLIP_VISION'],
        image: ['IMAGE'],
        crop: [['center', 'none']]
      }
    },
    input_order: {
      required: ['clip_vision', 'image', 'crop']
    },
    output: ['CLIP_VISION_OUTPUT'],
    output_is_list: [false],
    output_name: ['CLIP_VISION_OUTPUT'],
    name: 'CLIPVisionEncode',
    display_name: 'CLIP Vision Encode',
    description: '',
    python_module: 'nodes',
    category: 'conditioning',
    output_node: false
  },
  StyleModelApply: {
    input: {
      required: {
        conditioning: ['CONDITIONING'],
        style_model: ['STYLE_MODEL'],
        clip_vision_output: ['CLIP_VISION_OUTPUT'],
        strength: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.001
          }
        ],
        strength_type: [['multiply']]
      }
    },
    input_order: {
      required: [
        'conditioning',
        'style_model',
        'clip_vision_output',
        'strength',
        'strength_type'
      ]
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'StyleModelApply',
    display_name: 'Apply Style Model',
    description: '',
    python_module: 'nodes',
    category: 'conditioning/style_model',
    output_node: false
  },
  unCLIPConditioning: {
    input: {
      required: {
        conditioning: ['CONDITIONING'],
        clip_vision_output: ['CLIP_VISION_OUTPUT'],
        strength: [
          'FLOAT',
          {
            default: 1,
            min: -10,
            max: 10,
            step: 0.01
          }
        ],
        noise_augmentation: [
          'FLOAT',
          {
            default: 0,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'conditioning',
        'clip_vision_output',
        'strength',
        'noise_augmentation'
      ]
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'unCLIPConditioning',
    display_name: 'unCLIPConditioning',
    description: '',
    python_module: 'nodes',
    category: 'conditioning',
    output_node: false
  },
  ControlNetApply: {
    input: {
      required: {
        conditioning: ['CONDITIONING'],
        control_net: ['CONTROL_NET'],
        image: ['IMAGE'],
        strength: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['conditioning', 'control_net', 'image', 'strength']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'ControlNetApply',
    display_name: 'Apply ControlNet (OLD)',
    description: '',
    python_module: 'nodes',
    category: 'conditioning/controlnet',
    output_node: false,
    deprecated: true
  },
  ControlNetApplyAdvanced: {
    input: {
      required: {
        positive: ['CONDITIONING'],
        negative: ['CONDITIONING'],
        control_net: ['CONTROL_NET'],
        image: ['IMAGE'],
        strength: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        start_percent: [
          'FLOAT',
          {
            default: 0,
            min: 0,
            max: 1,
            step: 0.001
          }
        ],
        end_percent: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.001
          }
        ]
      },
      optional: {
        vae: ['VAE']
      }
    },
    input_order: {
      required: [
        'positive',
        'negative',
        'control_net',
        'image',
        'strength',
        'start_percent',
        'end_percent'
      ],
      optional: ['vae']
    },
    output: ['CONDITIONING', 'CONDITIONING'],
    output_is_list: [false, false],
    output_name: ['positive', 'negative'],
    name: 'ControlNetApplyAdvanced',
    display_name: 'Apply ControlNet',
    description: '',
    python_module: 'nodes',
    category: 'conditioning/controlnet',
    output_node: false
  },
  ControlNetLoader: {
    input: {
      required: {
        control_net_name: [[]]
      }
    },
    input_order: {
      required: ['control_net_name']
    },
    output: ['CONTROL_NET'],
    output_is_list: [false],
    output_name: ['CONTROL_NET'],
    name: 'ControlNetLoader',
    display_name: 'Load ControlNet Model',
    description: '',
    python_module: 'nodes',
    category: 'loaders',
    output_node: false
  },
  DiffControlNetLoader: {
    input: {
      required: {
        model: ['MODEL'],
        control_net_name: [[]]
      }
    },
    input_order: {
      required: ['model', 'control_net_name']
    },
    output: ['CONTROL_NET'],
    output_is_list: [false],
    output_name: ['CONTROL_NET'],
    name: 'DiffControlNetLoader',
    display_name: 'Load ControlNet Model (diff)',
    description: '',
    python_module: 'nodes',
    category: 'loaders',
    output_node: false
  },
  StyleModelLoader: {
    input: {
      required: {
        style_model_name: [[]]
      }
    },
    input_order: {
      required: ['style_model_name']
    },
    output: ['STYLE_MODEL'],
    output_is_list: [false],
    output_name: ['STYLE_MODEL'],
    name: 'StyleModelLoader',
    display_name: 'Load Style Model',
    description: '',
    python_module: 'nodes',
    category: 'loaders',
    output_node: false
  },
  CLIPVisionLoader: {
    input: {
      required: {
        clip_name: [[]]
      }
    },
    input_order: {
      required: ['clip_name']
    },
    output: ['CLIP_VISION'],
    output_is_list: [false],
    output_name: ['CLIP_VISION'],
    name: 'CLIPVisionLoader',
    display_name: 'Load CLIP Vision',
    description: '',
    python_module: 'nodes',
    category: 'loaders',
    output_node: false
  },
  VAEDecodeTiled: {
    input: {
      required: {
        samples: ['LATENT'],
        vae: ['VAE'],
        tile_size: [
          'INT',
          {
            default: 512,
            min: 128,
            max: 4096,
            step: 32
          }
        ],
        overlap: [
          'INT',
          {
            default: 64,
            min: 0,
            max: 4096,
            step: 32
          }
        ]
      }
    },
    input_order: {
      required: ['samples', 'vae', 'tile_size', 'overlap']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'VAEDecodeTiled',
    display_name: 'VAE Decode (Tiled)',
    description: '',
    python_module: 'nodes',
    category: '_for_testing',
    output_node: false
  },
  VAEEncodeTiled: {
    input: {
      required: {
        pixels: ['IMAGE'],
        vae: ['VAE'],
        tile_size: [
          'INT',
          {
            default: 512,
            min: 320,
            max: 4096,
            step: 64
          }
        ]
      }
    },
    input_order: {
      required: ['pixels', 'vae', 'tile_size']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'VAEEncodeTiled',
    display_name: 'VAE Encode (Tiled)',
    description: '',
    python_module: 'nodes',
    category: '_for_testing',
    output_node: false
  },
  unCLIPCheckpointLoader: {
    input: {
      required: {
        ckpt_name: [['Realistic_Vision_V5.0.safetensors']]
      }
    },
    input_order: {
      required: ['ckpt_name']
    },
    output: ['MODEL', 'CLIP', 'VAE', 'CLIP_VISION'],
    output_is_list: [false, false, false, false],
    output_name: ['MODEL', 'CLIP', 'VAE', 'CLIP_VISION'],
    name: 'unCLIPCheckpointLoader',
    display_name: 'unCLIPCheckpointLoader',
    description: '',
    python_module: 'nodes',
    category: 'loaders',
    output_node: false
  },
  GLIGENLoader: {
    input: {
      required: {
        gligen_name: [[]]
      }
    },
    input_order: {
      required: ['gligen_name']
    },
    output: ['GLIGEN'],
    output_is_list: [false],
    output_name: ['GLIGEN'],
    name: 'GLIGENLoader',
    display_name: 'GLIGENLoader',
    description: '',
    python_module: 'nodes',
    category: 'loaders',
    output_node: false
  },
  GLIGENTextBoxApply: {
    input: {
      required: {
        conditioning_to: ['CONDITIONING'],
        clip: ['CLIP'],
        gligen_textbox_model: ['GLIGEN'],
        text: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true
          }
        ],
        width: [
          'INT',
          {
            default: 64,
            min: 8,
            max: 16384,
            step: 8
          }
        ],
        height: [
          'INT',
          {
            default: 64,
            min: 8,
            max: 16384,
            step: 8
          }
        ],
        x: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        y: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ]
      }
    },
    input_order: {
      required: [
        'conditioning_to',
        'clip',
        'gligen_textbox_model',
        'text',
        'width',
        'height',
        'x',
        'y'
      ]
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'GLIGENTextBoxApply',
    display_name: 'GLIGENTextBoxApply',
    description: '',
    python_module: 'nodes',
    category: 'conditioning/gligen',
    output_node: false
  },
  InpaintModelConditioning: {
    input: {
      required: {
        positive: ['CONDITIONING'],
        negative: ['CONDITIONING'],
        vae: ['VAE'],
        pixels: ['IMAGE'],
        mask: ['MASK'],
        noise_mask: [
          'BOOLEAN',
          {
            default: true,
            tooltip:
              'Add a noise mask to the latent so sampling will only happen within the mask. Might improve results or completely break things depending on the model.'
          }
        ]
      }
    },
    input_order: {
      required: ['positive', 'negative', 'vae', 'pixels', 'mask', 'noise_mask']
    },
    output: ['CONDITIONING', 'CONDITIONING', 'LATENT'],
    output_is_list: [false, false, false],
    output_name: ['positive', 'negative', 'latent'],
    name: 'InpaintModelConditioning',
    display_name: 'InpaintModelConditioning',
    description: '',
    python_module: 'nodes',
    category: 'conditioning/inpaint',
    output_node: false
  },
  CheckpointLoader: {
    input: {
      required: {
        config_name: [
          [
            'anything_v3.yaml',
            'v1-inference.yaml',
            'v1-inference_clip_skip_2.yaml',
            'v1-inference_clip_skip_2_fp16.yaml',
            'v1-inference_fp16.yaml',
            'v1-inpainting-inference.yaml',
            'v2-inference-v.yaml',
            'v2-inference-v_fp32.yaml',
            'v2-inference.yaml',
            'v2-inference_fp32.yaml',
            'v2-inpainting-inference.yaml'
          ]
        ],
        ckpt_name: [['Realistic_Vision_V5.0.safetensors']]
      }
    },
    input_order: {
      required: ['config_name', 'ckpt_name']
    },
    output: ['MODEL', 'CLIP', 'VAE'],
    output_is_list: [false, false, false],
    output_name: ['MODEL', 'CLIP', 'VAE'],
    name: 'CheckpointLoader',
    display_name: 'Load Checkpoint With Config (DEPRECATED)',
    description: '',
    python_module: 'nodes',
    category: 'advanced/loaders',
    output_node: false,
    deprecated: true
  },
  DiffusersLoader: {
    input: {
      required: {
        model_path: [[]]
      }
    },
    input_order: {
      required: ['model_path']
    },
    output: ['MODEL', 'CLIP', 'VAE'],
    output_is_list: [false, false, false],
    output_name: ['MODEL', 'CLIP', 'VAE'],
    name: 'DiffusersLoader',
    display_name: 'DiffusersLoader',
    description: '',
    python_module: 'nodes',
    category: 'advanced/loaders/deprecated',
    output_node: false
  },
  LoadLatent: {
    input: {
      required: {
        latent: [[]]
      }
    },
    input_order: {
      required: ['latent']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LoadLatent',
    display_name: 'LoadLatent',
    description: '',
    python_module: 'nodes',
    category: '_for_testing',
    output_node: false
  },
  SaveLatent: {
    input: {
      required: {
        samples: ['LATENT'],
        filename_prefix: [
          'STRING',
          {
            default: 'latents/ComfyUI'
          }
        ]
      },
      hidden: {
        prompt: 'PROMPT',
        extra_pnginfo: 'EXTRA_PNGINFO'
      }
    },
    input_order: {
      required: ['samples', 'filename_prefix'],
      hidden: ['prompt', 'extra_pnginfo']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'SaveLatent',
    display_name: 'SaveLatent',
    description: '',
    python_module: 'nodes',
    category: '_for_testing',
    output_node: true
  },
  ConditioningZeroOut: {
    input: {
      required: {
        conditioning: ['CONDITIONING']
      }
    },
    input_order: {
      required: ['conditioning']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'ConditioningZeroOut',
    display_name: 'ConditioningZeroOut',
    description: '',
    python_module: 'nodes',
    category: 'advanced/conditioning',
    output_node: false
  },
  ConditioningSetTimestepRange: {
    input: {
      required: {
        conditioning: ['CONDITIONING'],
        start: [
          'FLOAT',
          {
            default: 0,
            min: 0,
            max: 1,
            step: 0.001
          }
        ],
        end: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.001
          }
        ]
      }
    },
    input_order: {
      required: ['conditioning', 'start', 'end']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'ConditioningSetTimestepRange',
    display_name: 'ConditioningSetTimestepRange',
    description: '',
    python_module: 'nodes',
    category: 'advanced/conditioning',
    output_node: false
  },
  LoraLoaderModelOnly: {
    input: {
      required: {
        model: ['MODEL'],
        lora_name: [['trapsgoonwild02.safetensors']],
        strength_model: [
          'FLOAT',
          {
            default: 1,
            min: -100,
            max: 100,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'lora_name', 'strength_model']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'LoraLoaderModelOnly',
    display_name: 'LoraLoaderModelOnly',
    description:
      'LoRAs are used to modify diffusion and CLIP models, altering the way in which latents are denoised such as applying styles. Multiple LoRA nodes can be linked together.',
    python_module: 'nodes',
    category: 'loaders',
    output_node: false,
    output_tooltips: [
      'The modified diffusion model.',
      'The modified CLIP model.'
    ]
  },
  LatentAdd: {
    input: {
      required: {
        samples1: ['LATENT'],
        samples2: ['LATENT']
      }
    },
    input_order: {
      required: ['samples1', 'samples2']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentAdd',
    display_name: 'LatentAdd',
    description: '',
    python_module: 'comfy_extras.nodes_latent',
    category: 'latent/advanced',
    output_node: false
  },
  LatentSubtract: {
    input: {
      required: {
        samples1: ['LATENT'],
        samples2: ['LATENT']
      }
    },
    input_order: {
      required: ['samples1', 'samples2']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentSubtract',
    display_name: 'LatentSubtract',
    description: '',
    python_module: 'comfy_extras.nodes_latent',
    category: 'latent/advanced',
    output_node: false
  },
  LatentMultiply: {
    input: {
      required: {
        samples: ['LATENT'],
        multiplier: [
          'FLOAT',
          {
            default: 1,
            min: -10,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['samples', 'multiplier']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentMultiply',
    display_name: 'LatentMultiply',
    description: '',
    python_module: 'comfy_extras.nodes_latent',
    category: 'latent/advanced',
    output_node: false
  },
  LatentInterpolate: {
    input: {
      required: {
        samples1: ['LATENT'],
        samples2: ['LATENT'],
        ratio: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['samples1', 'samples2', 'ratio']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentInterpolate',
    display_name: 'LatentInterpolate',
    description: '',
    python_module: 'comfy_extras.nodes_latent',
    category: 'latent/advanced',
    output_node: false
  },
  LatentBatch: {
    input: {
      required: {
        samples1: ['LATENT'],
        samples2: ['LATENT']
      }
    },
    input_order: {
      required: ['samples1', 'samples2']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentBatch',
    display_name: 'LatentBatch',
    description: '',
    python_module: 'comfy_extras.nodes_latent',
    category: 'latent/batch',
    output_node: false
  },
  LatentBatchSeedBehavior: {
    input: {
      required: {
        samples: ['LATENT'],
        seed_behavior: [
          ['random', 'fixed'],
          {
            default: 'fixed'
          }
        ]
      }
    },
    input_order: {
      required: ['samples', 'seed_behavior']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentBatchSeedBehavior',
    display_name: 'LatentBatchSeedBehavior',
    description: '',
    python_module: 'comfy_extras.nodes_latent',
    category: 'latent/advanced',
    output_node: false
  },
  LatentApplyOperation: {
    input: {
      required: {
        samples: ['LATENT'],
        operation: ['LATENT_OPERATION']
      }
    },
    input_order: {
      required: ['samples', 'operation']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentApplyOperation',
    display_name: 'LatentApplyOperation',
    description: '',
    python_module: 'comfy_extras.nodes_latent',
    category: 'latent/advanced/operations',
    output_node: false,
    experimental: true
  },
  LatentApplyOperationCFG: {
    input: {
      required: {
        model: ['MODEL'],
        operation: ['LATENT_OPERATION']
      }
    },
    input_order: {
      required: ['model', 'operation']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'LatentApplyOperationCFG',
    display_name: 'LatentApplyOperationCFG',
    description: '',
    python_module: 'comfy_extras.nodes_latent',
    category: 'latent/advanced/operations',
    output_node: false,
    experimental: true
  },
  LatentOperationTonemapReinhard: {
    input: {
      required: {
        multiplier: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['multiplier']
    },
    output: ['LATENT_OPERATION'],
    output_is_list: [false],
    output_name: ['LATENT_OPERATION'],
    name: 'LatentOperationTonemapReinhard',
    display_name: 'LatentOperationTonemapReinhard',
    description: '',
    python_module: 'comfy_extras.nodes_latent',
    category: 'latent/advanced/operations',
    output_node: false,
    experimental: true
  },
  LatentOperationSharpen: {
    input: {
      required: {
        sharpen_radius: [
          'INT',
          {
            default: 9,
            min: 1,
            max: 31,
            step: 1
          }
        ],
        sigma: [
          'FLOAT',
          {
            default: 1,
            min: 0.1,
            max: 10,
            step: 0.1
          }
        ],
        alpha: [
          'FLOAT',
          {
            default: 0.1,
            min: 0,
            max: 5,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['sharpen_radius', 'sigma', 'alpha']
    },
    output: ['LATENT_OPERATION'],
    output_is_list: [false],
    output_name: ['LATENT_OPERATION'],
    name: 'LatentOperationSharpen',
    display_name: 'LatentOperationSharpen',
    description: '',
    python_module: 'comfy_extras.nodes_latent',
    category: 'latent/advanced/operations',
    output_node: false,
    experimental: true
  },
  HypernetworkLoader: {
    input: {
      required: {
        model: ['MODEL'],
        hypernetwork_name: [[]],
        strength: [
          'FLOAT',
          {
            default: 1,
            min: -10,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'hypernetwork_name', 'strength']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'HypernetworkLoader',
    display_name: 'HypernetworkLoader',
    description: '',
    python_module: 'comfy_extras.nodes_hypernetwork',
    category: 'loaders',
    output_node: false
  },
  UpscaleModelLoader: {
    input: {
      required: {
        model_name: [[]]
      }
    },
    input_order: {
      required: ['model_name']
    },
    output: ['UPSCALE_MODEL'],
    output_is_list: [false],
    output_name: ['UPSCALE_MODEL'],
    name: 'UpscaleModelLoader',
    display_name: 'Load Upscale Model',
    description: '',
    python_module: 'comfy_extras.nodes_upscale_model',
    category: 'loaders',
    output_node: false
  },
  ImageUpscaleWithModel: {
    input: {
      required: {
        upscale_model: ['UPSCALE_MODEL'],
        image: ['IMAGE']
      }
    },
    input_order: {
      required: ['upscale_model', 'image']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'ImageUpscaleWithModel',
    display_name: 'Upscale Image (using Model)',
    description: '',
    python_module: 'comfy_extras.nodes_upscale_model',
    category: 'image/upscaling',
    output_node: false
  },
  ImageBlend: {
    input: {
      required: {
        image1: ['IMAGE'],
        image2: ['IMAGE'],
        blend_factor: [
          'FLOAT',
          {
            default: 0.5,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        blend_mode: [
          [
            'normal',
            'multiply',
            'screen',
            'overlay',
            'soft_light',
            'difference'
          ]
        ]
      }
    },
    input_order: {
      required: ['image1', 'image2', 'blend_factor', 'blend_mode']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'ImageBlend',
    display_name: 'Image Blend',
    description: '',
    python_module: 'comfy_extras.nodes_post_processing',
    category: 'image/postprocessing',
    output_node: false
  },
  ImageBlur: {
    input: {
      required: {
        image: ['IMAGE'],
        blur_radius: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 31,
            step: 1
          }
        ],
        sigma: [
          'FLOAT',
          {
            default: 1,
            min: 0.1,
            max: 10,
            step: 0.1
          }
        ]
      }
    },
    input_order: {
      required: ['image', 'blur_radius', 'sigma']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'ImageBlur',
    display_name: 'Image Blur',
    description: '',
    python_module: 'comfy_extras.nodes_post_processing',
    category: 'image/postprocessing',
    output_node: false
  },
  ImageQuantize: {
    input: {
      required: {
        image: ['IMAGE'],
        colors: [
          'INT',
          {
            default: 256,
            min: 1,
            max: 256,
            step: 1
          }
        ],
        dither: [
          [
            'none',
            'floyd-steinberg',
            'bayer-2',
            'bayer-4',
            'bayer-8',
            'bayer-16'
          ]
        ]
      }
    },
    input_order: {
      required: ['image', 'colors', 'dither']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'ImageQuantize',
    display_name: 'Image Quantize',
    description: '',
    python_module: 'comfy_extras.nodes_post_processing',
    category: 'image/postprocessing',
    output_node: false
  },
  ImageSharpen: {
    input: {
      required: {
        image: ['IMAGE'],
        sharpen_radius: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 31,
            step: 1
          }
        ],
        sigma: [
          'FLOAT',
          {
            default: 1,
            min: 0.1,
            max: 10,
            step: 0.01
          }
        ],
        alpha: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 5,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['image', 'sharpen_radius', 'sigma', 'alpha']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'ImageSharpen',
    display_name: 'Image Sharpen',
    description: '',
    python_module: 'comfy_extras.nodes_post_processing',
    category: 'image/postprocessing',
    output_node: false
  },
  ImageScaleToTotalPixels: {
    input: {
      required: {
        image: ['IMAGE'],
        upscale_method: [
          ['nearest-exact', 'bilinear', 'area', 'bicubic', 'lanczos']
        ],
        megapixels: [
          'FLOAT',
          {
            default: 1,
            min: 0.01,
            max: 16,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['image', 'upscale_method', 'megapixels']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'ImageScaleToTotalPixels',
    display_name: 'Scale Image to Total Pixels',
    description: '',
    python_module: 'comfy_extras.nodes_post_processing',
    category: 'image/upscaling',
    output_node: false
  },
  LatentCompositeMasked: {
    input: {
      required: {
        destination: ['LATENT'],
        source: ['LATENT'],
        x: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        y: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 8
          }
        ],
        resize_source: [
          'BOOLEAN',
          {
            default: false
          }
        ]
      },
      optional: {
        mask: ['MASK']
      }
    },
    input_order: {
      required: ['destination', 'source', 'x', 'y', 'resize_source'],
      optional: ['mask']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'LatentCompositeMasked',
    display_name: 'LatentCompositeMasked',
    description: '',
    python_module: 'comfy_extras.nodes_mask',
    category: 'latent',
    output_node: false
  },
  ImageCompositeMasked: {
    input: {
      required: {
        destination: ['IMAGE'],
        source: ['IMAGE'],
        x: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        y: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        resize_source: [
          'BOOLEAN',
          {
            default: false
          }
        ]
      },
      optional: {
        mask: ['MASK']
      }
    },
    input_order: {
      required: ['destination', 'source', 'x', 'y', 'resize_source'],
      optional: ['mask']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'ImageCompositeMasked',
    display_name: 'ImageCompositeMasked',
    description: '',
    python_module: 'comfy_extras.nodes_mask',
    category: 'image',
    output_node: false
  },
  MaskToImage: {
    input: {
      required: {
        mask: ['MASK']
      }
    },
    input_order: {
      required: ['mask']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'MaskToImage',
    display_name: 'Convert Mask to Image',
    description: '',
    python_module: 'comfy_extras.nodes_mask',
    category: 'mask',
    output_node: false
  },
  ImageToMask: {
    input: {
      required: {
        image: ['IMAGE'],
        channel: [['red', 'green', 'blue', 'alpha']]
      }
    },
    input_order: {
      required: ['image', 'channel']
    },
    output: ['MASK'],
    output_is_list: [false],
    output_name: ['MASK'],
    name: 'ImageToMask',
    display_name: 'Convert Image to Mask',
    description: '',
    python_module: 'comfy_extras.nodes_mask',
    category: 'mask',
    output_node: false
  },
  ImageColorToMask: {
    input: {
      required: {
        image: ['IMAGE'],
        color: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16777215,
            step: 1,
            display: 'color'
          }
        ]
      }
    },
    input_order: {
      required: ['image', 'color']
    },
    output: ['MASK'],
    output_is_list: [false],
    output_name: ['MASK'],
    name: 'ImageColorToMask',
    display_name: 'ImageColorToMask',
    description: '',
    python_module: 'comfy_extras.nodes_mask',
    category: 'mask',
    output_node: false
  },
  SolidMask: {
    input: {
      required: {
        value: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        width: [
          'INT',
          {
            default: 512,
            min: 1,
            max: 16384,
            step: 1
          }
        ],
        height: [
          'INT',
          {
            default: 512,
            min: 1,
            max: 16384,
            step: 1
          }
        ]
      }
    },
    input_order: {
      required: ['value', 'width', 'height']
    },
    output: ['MASK'],
    output_is_list: [false],
    output_name: ['MASK'],
    name: 'SolidMask',
    display_name: 'SolidMask',
    description: '',
    python_module: 'comfy_extras.nodes_mask',
    category: 'mask',
    output_node: false
  },
  InvertMask: {
    input: {
      required: {
        mask: ['MASK']
      }
    },
    input_order: {
      required: ['mask']
    },
    output: ['MASK'],
    output_is_list: [false],
    output_name: ['MASK'],
    name: 'InvertMask',
    display_name: 'InvertMask',
    description: '',
    python_module: 'comfy_extras.nodes_mask',
    category: 'mask',
    output_node: false
  },
  CropMask: {
    input: {
      required: {
        mask: ['MASK'],
        x: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        y: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        width: [
          'INT',
          {
            default: 512,
            min: 1,
            max: 16384,
            step: 1
          }
        ],
        height: [
          'INT',
          {
            default: 512,
            min: 1,
            max: 16384,
            step: 1
          }
        ]
      }
    },
    input_order: {
      required: ['mask', 'x', 'y', 'width', 'height']
    },
    output: ['MASK'],
    output_is_list: [false],
    output_name: ['MASK'],
    name: 'CropMask',
    display_name: 'CropMask',
    description: '',
    python_module: 'comfy_extras.nodes_mask',
    category: 'mask',
    output_node: false
  },
  MaskComposite: {
    input: {
      required: {
        destination: ['MASK'],
        source: ['MASK'],
        x: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        y: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        operation: [['multiply', 'add', 'subtract', 'and', 'or', 'xor']]
      }
    },
    input_order: {
      required: ['destination', 'source', 'x', 'y', 'operation']
    },
    output: ['MASK'],
    output_is_list: [false],
    output_name: ['MASK'],
    name: 'MaskComposite',
    display_name: 'MaskComposite',
    description: '',
    python_module: 'comfy_extras.nodes_mask',
    category: 'mask',
    output_node: false
  },
  FeatherMask: {
    input: {
      required: {
        mask: ['MASK'],
        left: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        top: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        right: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        bottom: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ]
      }
    },
    input_order: {
      required: ['mask', 'left', 'top', 'right', 'bottom']
    },
    output: ['MASK'],
    output_is_list: [false],
    output_name: ['MASK'],
    name: 'FeatherMask',
    display_name: 'FeatherMask',
    description: '',
    python_module: 'comfy_extras.nodes_mask',
    category: 'mask',
    output_node: false
  },
  GrowMask: {
    input: {
      required: {
        mask: ['MASK'],
        expand: [
          'INT',
          {
            default: 0,
            min: -16384,
            max: 16384,
            step: 1
          }
        ],
        tapered_corners: [
          'BOOLEAN',
          {
            default: true
          }
        ]
      }
    },
    input_order: {
      required: ['mask', 'expand', 'tapered_corners']
    },
    output: ['MASK'],
    output_is_list: [false],
    output_name: ['MASK'],
    name: 'GrowMask',
    display_name: 'GrowMask',
    description: '',
    python_module: 'comfy_extras.nodes_mask',
    category: 'mask',
    output_node: false
  },
  ThresholdMask: {
    input: {
      required: {
        mask: ['MASK'],
        value: [
          'FLOAT',
          {
            default: 0.5,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['mask', 'value']
    },
    output: ['MASK'],
    output_is_list: [false],
    output_name: ['MASK'],
    name: 'ThresholdMask',
    display_name: 'ThresholdMask',
    description: '',
    python_module: 'comfy_extras.nodes_mask',
    category: 'mask',
    output_node: false
  },
  PorterDuffImageComposite: {
    input: {
      required: {
        source: ['IMAGE'],
        source_alpha: ['MASK'],
        destination: ['IMAGE'],
        destination_alpha: ['MASK'],
        mode: [
          [
            'ADD',
            'CLEAR',
            'DARKEN',
            'DST',
            'DST_ATOP',
            'DST_IN',
            'DST_OUT',
            'DST_OVER',
            'LIGHTEN',
            'MULTIPLY',
            'OVERLAY',
            'SCREEN',
            'SRC',
            'SRC_ATOP',
            'SRC_IN',
            'SRC_OUT',
            'SRC_OVER',
            'XOR'
          ],
          {
            default: 'DST'
          }
        ]
      }
    },
    input_order: {
      required: [
        'source',
        'source_alpha',
        'destination',
        'destination_alpha',
        'mode'
      ]
    },
    output: ['IMAGE', 'MASK'],
    output_is_list: [false, false],
    output_name: ['IMAGE', 'MASK'],
    name: 'PorterDuffImageComposite',
    display_name: 'Porter-Duff Image Composite',
    description: '',
    python_module: 'comfy_extras.nodes_compositing',
    category: 'mask/compositing',
    output_node: false
  },
  SplitImageWithAlpha: {
    input: {
      required: {
        image: ['IMAGE']
      }
    },
    input_order: {
      required: ['image']
    },
    output: ['IMAGE', 'MASK'],
    output_is_list: [false, false],
    output_name: ['IMAGE', 'MASK'],
    name: 'SplitImageWithAlpha',
    display_name: 'Split Image with Alpha',
    description: '',
    python_module: 'comfy_extras.nodes_compositing',
    category: 'mask/compositing',
    output_node: false
  },
  JoinImageWithAlpha: {
    input: {
      required: {
        image: ['IMAGE'],
        alpha: ['MASK']
      }
    },
    input_order: {
      required: ['image', 'alpha']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'JoinImageWithAlpha',
    display_name: 'Join Image with Alpha',
    description: '',
    python_module: 'comfy_extras.nodes_compositing',
    category: 'mask/compositing',
    output_node: false
  },
  RebatchLatents: {
    input: {
      required: {
        latents: ['LATENT'],
        batch_size: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096
          }
        ]
      }
    },
    input_order: {
      required: ['latents', 'batch_size']
    },
    output: ['LATENT'],
    output_is_list: [true],
    output_name: ['LATENT'],
    name: 'RebatchLatents',
    display_name: 'Rebatch Latents',
    description: '',
    python_module: 'comfy_extras.nodes_rebatch',
    category: 'latent/batch',
    output_node: false
  },
  RebatchImages: {
    input: {
      required: {
        images: ['IMAGE'],
        batch_size: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096
          }
        ]
      }
    },
    input_order: {
      required: ['images', 'batch_size']
    },
    output: ['IMAGE'],
    output_is_list: [true],
    output_name: ['IMAGE'],
    name: 'RebatchImages',
    display_name: 'Rebatch Images',
    description: '',
    python_module: 'comfy_extras.nodes_rebatch',
    category: 'image/batch',
    output_node: false
  },
  ModelMergeSimple: {
    input: {
      required: {
        model1: ['MODEL'],
        model2: ['MODEL'],
        ratio: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model1', 'model2', 'ratio']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelMergeSimple',
    display_name: 'ModelMergeSimple',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging',
    category: 'advanced/model_merging',
    output_node: false
  },
  ModelMergeBlocks: {
    input: {
      required: {
        model1: ['MODEL'],
        model2: ['MODEL'],
        input: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        middle: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        out: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model1', 'model2', 'input', 'middle', 'out']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelMergeBlocks',
    display_name: 'ModelMergeBlocks',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging',
    category: 'advanced/model_merging',
    output_node: false
  },
  ModelMergeSubtract: {
    input: {
      required: {
        model1: ['MODEL'],
        model2: ['MODEL'],
        multiplier: [
          'FLOAT',
          {
            default: 1,
            min: -10,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model1', 'model2', 'multiplier']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelMergeSubtract',
    display_name: 'ModelMergeSubtract',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging',
    category: 'advanced/model_merging',
    output_node: false
  },
  ModelMergeAdd: {
    input: {
      required: {
        model1: ['MODEL'],
        model2: ['MODEL']
      }
    },
    input_order: {
      required: ['model1', 'model2']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelMergeAdd',
    display_name: 'ModelMergeAdd',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging',
    category: 'advanced/model_merging',
    output_node: false
  },
  CheckpointSave: {
    input: {
      required: {
        model: ['MODEL'],
        clip: ['CLIP'],
        vae: ['VAE'],
        filename_prefix: [
          'STRING',
          {
            default: 'checkpoints/ComfyUI'
          }
        ]
      },
      hidden: {
        prompt: 'PROMPT',
        extra_pnginfo: 'EXTRA_PNGINFO'
      }
    },
    input_order: {
      required: ['model', 'clip', 'vae', 'filename_prefix'],
      hidden: ['prompt', 'extra_pnginfo']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'CheckpointSave',
    display_name: 'Save Checkpoint',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging',
    category: 'advanced/model_merging',
    output_node: true
  },
  CLIPMergeSimple: {
    input: {
      required: {
        clip1: ['CLIP'],
        clip2: ['CLIP'],
        ratio: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['clip1', 'clip2', 'ratio']
    },
    output: ['CLIP'],
    output_is_list: [false],
    output_name: ['CLIP'],
    name: 'CLIPMergeSimple',
    display_name: 'CLIPMergeSimple',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging',
    category: 'advanced/model_merging',
    output_node: false
  },
  CLIPMergeSubtract: {
    input: {
      required: {
        clip1: ['CLIP'],
        clip2: ['CLIP'],
        multiplier: [
          'FLOAT',
          {
            default: 1,
            min: -10,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['clip1', 'clip2', 'multiplier']
    },
    output: ['CLIP'],
    output_is_list: [false],
    output_name: ['CLIP'],
    name: 'CLIPMergeSubtract',
    display_name: 'CLIPMergeSubtract',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging',
    category: 'advanced/model_merging',
    output_node: false
  },
  CLIPMergeAdd: {
    input: {
      required: {
        clip1: ['CLIP'],
        clip2: ['CLIP']
      }
    },
    input_order: {
      required: ['clip1', 'clip2']
    },
    output: ['CLIP'],
    output_is_list: [false],
    output_name: ['CLIP'],
    name: 'CLIPMergeAdd',
    display_name: 'CLIPMergeAdd',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging',
    category: 'advanced/model_merging',
    output_node: false
  },
  CLIPSave: {
    input: {
      required: {
        clip: ['CLIP'],
        filename_prefix: [
          'STRING',
          {
            default: 'clip/ComfyUI'
          }
        ]
      },
      hidden: {
        prompt: 'PROMPT',
        extra_pnginfo: 'EXTRA_PNGINFO'
      }
    },
    input_order: {
      required: ['clip', 'filename_prefix'],
      hidden: ['prompt', 'extra_pnginfo']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'CLIPSave',
    display_name: 'CLIPSave',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging',
    category: 'advanced/model_merging',
    output_node: true
  },
  VAESave: {
    input: {
      required: {
        vae: ['VAE'],
        filename_prefix: [
          'STRING',
          {
            default: 'vae/ComfyUI_vae'
          }
        ]
      },
      hidden: {
        prompt: 'PROMPT',
        extra_pnginfo: 'EXTRA_PNGINFO'
      }
    },
    input_order: {
      required: ['vae', 'filename_prefix'],
      hidden: ['prompt', 'extra_pnginfo']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'VAESave',
    display_name: 'VAESave',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging',
    category: 'advanced/model_merging',
    output_node: true
  },
  ModelSave: {
    input: {
      required: {
        model: ['MODEL'],
        filename_prefix: [
          'STRING',
          {
            default: 'diffusion_models/ComfyUI'
          }
        ]
      },
      hidden: {
        prompt: 'PROMPT',
        extra_pnginfo: 'EXTRA_PNGINFO'
      }
    },
    input_order: {
      required: ['model', 'filename_prefix'],
      hidden: ['prompt', 'extra_pnginfo']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'ModelSave',
    display_name: 'ModelSave',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging',
    category: 'advanced/model_merging',
    output_node: true
  },
  TomePatchModel: {
    input: {
      required: {
        model: ['MODEL'],
        ratio: [
          'FLOAT',
          {
            default: 0.3,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'ratio']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'TomePatchModel',
    display_name: 'TomePatchModel',
    description: '',
    python_module: 'comfy_extras.nodes_tomesd',
    category: 'model_patches/unet',
    output_node: false
  },
  CLIPTextEncodeSDXLRefiner: {
    input: {
      required: {
        ascore: [
          'FLOAT',
          {
            default: 6,
            min: 0,
            max: 1000,
            step: 0.01
          }
        ],
        width: [
          'INT',
          {
            default: 1024,
            min: 0,
            max: 16384
          }
        ],
        height: [
          'INT',
          {
            default: 1024,
            min: 0,
            max: 16384
          }
        ],
        text: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true
          }
        ],
        clip: ['CLIP']
      }
    },
    input_order: {
      required: ['ascore', 'width', 'height', 'text', 'clip']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'CLIPTextEncodeSDXLRefiner',
    display_name: 'CLIPTextEncodeSDXLRefiner',
    description: '',
    python_module: 'comfy_extras.nodes_clip_sdxl',
    category: 'advanced/conditioning',
    output_node: false
  },
  CLIPTextEncodeSDXL: {
    input: {
      required: {
        width: [
          'INT',
          {
            default: 1024,
            min: 0,
            max: 16384
          }
        ],
        height: [
          'INT',
          {
            default: 1024,
            min: 0,
            max: 16384
          }
        ],
        crop_w: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384
          }
        ],
        crop_h: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384
          }
        ],
        target_width: [
          'INT',
          {
            default: 1024,
            min: 0,
            max: 16384
          }
        ],
        target_height: [
          'INT',
          {
            default: 1024,
            min: 0,
            max: 16384
          }
        ],
        text_g: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true
          }
        ],
        clip: ['CLIP'],
        text_l: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true
          }
        ]
      }
    },
    input_order: {
      required: [
        'width',
        'height',
        'crop_w',
        'crop_h',
        'target_width',
        'target_height',
        'text_g',
        'clip',
        'text_l'
      ]
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'CLIPTextEncodeSDXL',
    display_name: 'CLIPTextEncodeSDXL',
    description: '',
    python_module: 'comfy_extras.nodes_clip_sdxl',
    category: 'advanced/conditioning',
    output_node: false
  },
  Canny: {
    input: {
      required: {
        image: ['IMAGE'],
        low_threshold: [
          'FLOAT',
          {
            default: 0.4,
            min: 0.01,
            max: 0.99,
            step: 0.01
          }
        ],
        high_threshold: [
          'FLOAT',
          {
            default: 0.8,
            min: 0.01,
            max: 0.99,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['image', 'low_threshold', 'high_threshold']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'Canny',
    display_name: 'Canny',
    description: '',
    python_module: 'comfy_extras.nodes_canny',
    category: 'image/preprocessors',
    output_node: false
  },
  FreeU: {
    input: {
      required: {
        model: ['MODEL'],
        b1: [
          'FLOAT',
          {
            default: 1.1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        b2: [
          'FLOAT',
          {
            default: 1.2,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        s1: [
          'FLOAT',
          {
            default: 0.9,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        s2: [
          'FLOAT',
          {
            default: 0.2,
            min: 0,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'b1', 'b2', 's1', 's2']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'FreeU',
    display_name: 'FreeU',
    description: '',
    python_module: 'comfy_extras.nodes_freelunch',
    category: 'model_patches/unet',
    output_node: false
  },
  FreeU_V2: {
    input: {
      required: {
        model: ['MODEL'],
        b1: [
          'FLOAT',
          {
            default: 1.3,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        b2: [
          'FLOAT',
          {
            default: 1.4,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        s1: [
          'FLOAT',
          {
            default: 0.9,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        s2: [
          'FLOAT',
          {
            default: 0.2,
            min: 0,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'b1', 'b2', 's1', 's2']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'FreeU_V2',
    display_name: 'FreeU_V2',
    description: '',
    python_module: 'comfy_extras.nodes_freelunch',
    category: 'model_patches/unet',
    output_node: false
  },
  SamplerCustom: {
    input: {
      required: {
        model: ['MODEL'],
        add_noise: [
          'BOOLEAN',
          {
            default: true
          }
        ],
        noise_seed: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 18446744073709552000
          }
        ],
        cfg: [
          'FLOAT',
          {
            default: 8,
            min: 0,
            max: 100,
            step: 0.1,
            round: 0.01
          }
        ],
        positive: ['CONDITIONING'],
        negative: ['CONDITIONING'],
        sampler: ['SAMPLER'],
        sigmas: ['SIGMAS'],
        latent_image: ['LATENT']
      }
    },
    input_order: {
      required: [
        'model',
        'add_noise',
        'noise_seed',
        'cfg',
        'positive',
        'negative',
        'sampler',
        'sigmas',
        'latent_image'
      ]
    },
    output: ['LATENT', 'LATENT'],
    output_is_list: [false, false],
    output_name: ['output', 'denoised_output'],
    name: 'SamplerCustom',
    display_name: 'SamplerCustom',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling',
    output_node: false
  },
  BasicScheduler: {
    input: {
      required: {
        model: ['MODEL'],
        scheduler: [
          [
            'normal',
            'karras',
            'exponential',
            'sgm_uniform',
            'simple',
            'ddim_uniform',
            'beta',
            'linear_quadratic'
          ]
        ],
        steps: [
          'INT',
          {
            default: 20,
            min: 1,
            max: 10000
          }
        ],
        denoise: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'scheduler', 'steps', 'denoise']
    },
    output: ['SIGMAS'],
    output_is_list: [false],
    output_name: ['SIGMAS'],
    name: 'BasicScheduler',
    display_name: 'BasicScheduler',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/schedulers',
    output_node: false
  },
  KarrasScheduler: {
    input: {
      required: {
        steps: [
          'INT',
          {
            default: 20,
            min: 1,
            max: 10000
          }
        ],
        sigma_max: [
          'FLOAT',
          {
            default: 14.614642,
            min: 0,
            max: 5000,
            step: 0.01,
            round: false
          }
        ],
        sigma_min: [
          'FLOAT',
          {
            default: 0.0291675,
            min: 0,
            max: 5000,
            step: 0.01,
            round: false
          }
        ],
        rho: [
          'FLOAT',
          {
            default: 7,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: ['steps', 'sigma_max', 'sigma_min', 'rho']
    },
    output: ['SIGMAS'],
    output_is_list: [false],
    output_name: ['SIGMAS'],
    name: 'KarrasScheduler',
    display_name: 'KarrasScheduler',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/schedulers',
    output_node: false
  },
  ExponentialScheduler: {
    input: {
      required: {
        steps: [
          'INT',
          {
            default: 20,
            min: 1,
            max: 10000
          }
        ],
        sigma_max: [
          'FLOAT',
          {
            default: 14.614642,
            min: 0,
            max: 5000,
            step: 0.01,
            round: false
          }
        ],
        sigma_min: [
          'FLOAT',
          {
            default: 0.0291675,
            min: 0,
            max: 5000,
            step: 0.01,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: ['steps', 'sigma_max', 'sigma_min']
    },
    output: ['SIGMAS'],
    output_is_list: [false],
    output_name: ['SIGMAS'],
    name: 'ExponentialScheduler',
    display_name: 'ExponentialScheduler',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/schedulers',
    output_node: false
  },
  PolyexponentialScheduler: {
    input: {
      required: {
        steps: [
          'INT',
          {
            default: 20,
            min: 1,
            max: 10000
          }
        ],
        sigma_max: [
          'FLOAT',
          {
            default: 14.614642,
            min: 0,
            max: 5000,
            step: 0.01,
            round: false
          }
        ],
        sigma_min: [
          'FLOAT',
          {
            default: 0.0291675,
            min: 0,
            max: 5000,
            step: 0.01,
            round: false
          }
        ],
        rho: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: ['steps', 'sigma_max', 'sigma_min', 'rho']
    },
    output: ['SIGMAS'],
    output_is_list: [false],
    output_name: ['SIGMAS'],
    name: 'PolyexponentialScheduler',
    display_name: 'PolyexponentialScheduler',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/schedulers',
    output_node: false
  },
  LaplaceScheduler: {
    input: {
      required: {
        steps: [
          'INT',
          {
            default: 20,
            min: 1,
            max: 10000
          }
        ],
        sigma_max: [
          'FLOAT',
          {
            default: 14.614642,
            min: 0,
            max: 5000,
            step: 0.01,
            round: false
          }
        ],
        sigma_min: [
          'FLOAT',
          {
            default: 0.0291675,
            min: 0,
            max: 5000,
            step: 0.01,
            round: false
          }
        ],
        mu: [
          'FLOAT',
          {
            default: 0,
            min: -10,
            max: 10,
            step: 0.1,
            round: false
          }
        ],
        beta: [
          'FLOAT',
          {
            default: 0.5,
            min: 0,
            max: 10,
            step: 0.1,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: ['steps', 'sigma_max', 'sigma_min', 'mu', 'beta']
    },
    output: ['SIGMAS'],
    output_is_list: [false],
    output_name: ['SIGMAS'],
    name: 'LaplaceScheduler',
    display_name: 'LaplaceScheduler',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/schedulers',
    output_node: false
  },
  VPScheduler: {
    input: {
      required: {
        steps: [
          'INT',
          {
            default: 20,
            min: 1,
            max: 10000
          }
        ],
        beta_d: [
          'FLOAT',
          {
            default: 19.9,
            min: 0,
            max: 5000,
            step: 0.01,
            round: false
          }
        ],
        beta_min: [
          'FLOAT',
          {
            default: 0.1,
            min: 0,
            max: 5000,
            step: 0.01,
            round: false
          }
        ],
        eps_s: [
          'FLOAT',
          {
            default: 0.001,
            min: 0,
            max: 1,
            step: 0.0001,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: ['steps', 'beta_d', 'beta_min', 'eps_s']
    },
    output: ['SIGMAS'],
    output_is_list: [false],
    output_name: ['SIGMAS'],
    name: 'VPScheduler',
    display_name: 'VPScheduler',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/schedulers',
    output_node: false
  },
  BetaSamplingScheduler: {
    input: {
      required: {
        model: ['MODEL'],
        steps: [
          'INT',
          {
            default: 20,
            min: 1,
            max: 10000
          }
        ],
        alpha: [
          'FLOAT',
          {
            default: 0.6,
            min: 0,
            max: 50,
            step: 0.01,
            round: false
          }
        ],
        beta: [
          'FLOAT',
          {
            default: 0.6,
            min: 0,
            max: 50,
            step: 0.01,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'steps', 'alpha', 'beta']
    },
    output: ['SIGMAS'],
    output_is_list: [false],
    output_name: ['SIGMAS'],
    name: 'BetaSamplingScheduler',
    display_name: 'BetaSamplingScheduler',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/schedulers',
    output_node: false
  },
  SDTurboScheduler: {
    input: {
      required: {
        model: ['MODEL'],
        steps: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 10
          }
        ],
        denoise: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'steps', 'denoise']
    },
    output: ['SIGMAS'],
    output_is_list: [false],
    output_name: ['SIGMAS'],
    name: 'SDTurboScheduler',
    display_name: 'SDTurboScheduler',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/schedulers',
    output_node: false
  },
  KSamplerSelect: {
    input: {
      required: {
        sampler_name: [
          [
            'euler',
            'euler_cfg_pp',
            'euler_ancestral',
            'euler_ancestral_cfg_pp',
            'heun',
            'heunpp2',
            'dpm_2',
            'dpm_2_ancestral',
            'lms',
            'dpm_fast',
            'dpm_adaptive',
            'dpmpp_2s_ancestral',
            'dpmpp_2s_ancestral_cfg_pp',
            'dpmpp_sde',
            'dpmpp_sde_gpu',
            'dpmpp_2m',
            'dpmpp_2m_cfg_pp',
            'dpmpp_2m_sde',
            'dpmpp_2m_sde_gpu',
            'dpmpp_3m_sde',
            'dpmpp_3m_sde_gpu',
            'ddpm',
            'lcm',
            'ipndm',
            'ipndm_v',
            'deis',
            'ddim',
            'uni_pc',
            'uni_pc_bh2'
          ]
        ]
      }
    },
    input_order: {
      required: ['sampler_name']
    },
    output: ['SAMPLER'],
    output_is_list: [false],
    output_name: ['SAMPLER'],
    name: 'KSamplerSelect',
    display_name: 'KSamplerSelect',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/samplers',
    output_node: false
  },
  SamplerEulerAncestral: {
    input: {
      required: {
        eta: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        s_noise: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: ['eta', 's_noise']
    },
    output: ['SAMPLER'],
    output_is_list: [false],
    output_name: ['SAMPLER'],
    name: 'SamplerEulerAncestral',
    display_name: 'SamplerEulerAncestral',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/samplers',
    output_node: false
  },
  SamplerEulerAncestralCFGPP: {
    input: {
      required: {
        eta: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01,
            round: false
          }
        ],
        s_noise: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: ['eta', 's_noise']
    },
    output: ['SAMPLER'],
    output_is_list: [false],
    output_name: ['SAMPLER'],
    name: 'SamplerEulerAncestralCFGPP',
    display_name: 'SamplerEulerAncestralCFG++',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/samplers',
    output_node: false
  },
  SamplerLMS: {
    input: {
      required: {
        order: [
          'INT',
          {
            default: 4,
            min: 1,
            max: 100
          }
        ]
      }
    },
    input_order: {
      required: ['order']
    },
    output: ['SAMPLER'],
    output_is_list: [false],
    output_name: ['SAMPLER'],
    name: 'SamplerLMS',
    display_name: 'SamplerLMS',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/samplers',
    output_node: false
  },
  SamplerDPMPP_3M_SDE: {
    input: {
      required: {
        eta: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        s_noise: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        noise_device: [['gpu', 'cpu']]
      }
    },
    input_order: {
      required: ['eta', 's_noise', 'noise_device']
    },
    output: ['SAMPLER'],
    output_is_list: [false],
    output_name: ['SAMPLER'],
    name: 'SamplerDPMPP_3M_SDE',
    display_name: 'SamplerDPMPP_3M_SDE',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/samplers',
    output_node: false
  },
  SamplerDPMPP_2M_SDE: {
    input: {
      required: {
        solver_type: [['midpoint', 'heun']],
        eta: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        s_noise: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        noise_device: [['gpu', 'cpu']]
      }
    },
    input_order: {
      required: ['solver_type', 'eta', 's_noise', 'noise_device']
    },
    output: ['SAMPLER'],
    output_is_list: [false],
    output_name: ['SAMPLER'],
    name: 'SamplerDPMPP_2M_SDE',
    display_name: 'SamplerDPMPP_2M_SDE',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/samplers',
    output_node: false
  },
  SamplerDPMPP_SDE: {
    input: {
      required: {
        eta: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        s_noise: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        r: [
          'FLOAT',
          {
            default: 0.5,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        noise_device: [['gpu', 'cpu']]
      }
    },
    input_order: {
      required: ['eta', 's_noise', 'r', 'noise_device']
    },
    output: ['SAMPLER'],
    output_is_list: [false],
    output_name: ['SAMPLER'],
    name: 'SamplerDPMPP_SDE',
    display_name: 'SamplerDPMPP_SDE',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/samplers',
    output_node: false
  },
  SamplerDPMPP_2S_Ancestral: {
    input: {
      required: {
        eta: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        s_noise: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: ['eta', 's_noise']
    },
    output: ['SAMPLER'],
    output_is_list: [false],
    output_name: ['SAMPLER'],
    name: 'SamplerDPMPP_2S_Ancestral',
    display_name: 'SamplerDPMPP_2S_Ancestral',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/samplers',
    output_node: false
  },
  SamplerDPMAdaptative: {
    input: {
      required: {
        order: [
          'INT',
          {
            default: 3,
            min: 2,
            max: 3
          }
        ],
        rtol: [
          'FLOAT',
          {
            default: 0.05,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        atol: [
          'FLOAT',
          {
            default: 0.0078,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        h_init: [
          'FLOAT',
          {
            default: 0.05,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        pcoeff: [
          'FLOAT',
          {
            default: 0,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        icoeff: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        dcoeff: [
          'FLOAT',
          {
            default: 0,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        accept_safety: [
          'FLOAT',
          {
            default: 0.81,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        eta: [
          'FLOAT',
          {
            default: 0,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ],
        s_noise: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: [
        'order',
        'rtol',
        'atol',
        'h_init',
        'pcoeff',
        'icoeff',
        'dcoeff',
        'accept_safety',
        'eta',
        's_noise'
      ]
    },
    output: ['SAMPLER'],
    output_is_list: [false],
    output_name: ['SAMPLER'],
    name: 'SamplerDPMAdaptative',
    display_name: 'SamplerDPMAdaptative',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/samplers',
    output_node: false
  },
  SplitSigmas: {
    input: {
      required: {
        sigmas: ['SIGMAS'],
        step: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 10000
          }
        ]
      }
    },
    input_order: {
      required: ['sigmas', 'step']
    },
    output: ['SIGMAS', 'SIGMAS'],
    output_is_list: [false, false],
    output_name: ['high_sigmas', 'low_sigmas'],
    name: 'SplitSigmas',
    display_name: 'SplitSigmas',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/sigmas',
    output_node: false
  },
  SplitSigmasDenoise: {
    input: {
      required: {
        sigmas: ['SIGMAS'],
        denoise: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['sigmas', 'denoise']
    },
    output: ['SIGMAS', 'SIGMAS'],
    output_is_list: [false, false],
    output_name: ['high_sigmas', 'low_sigmas'],
    name: 'SplitSigmasDenoise',
    display_name: 'SplitSigmasDenoise',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/sigmas',
    output_node: false
  },
  FlipSigmas: {
    input: {
      required: {
        sigmas: ['SIGMAS']
      }
    },
    input_order: {
      required: ['sigmas']
    },
    output: ['SIGMAS'],
    output_is_list: [false],
    output_name: ['SIGMAS'],
    name: 'FlipSigmas',
    display_name: 'FlipSigmas',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/sigmas',
    output_node: false
  },
  CFGGuider: {
    input: {
      required: {
        model: ['MODEL'],
        positive: ['CONDITIONING'],
        negative: ['CONDITIONING'],
        cfg: [
          'FLOAT',
          {
            default: 8,
            min: 0,
            max: 100,
            step: 0.1,
            round: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'positive', 'negative', 'cfg']
    },
    output: ['GUIDER'],
    output_is_list: [false],
    output_name: ['GUIDER'],
    name: 'CFGGuider',
    display_name: 'CFGGuider',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/guiders',
    output_node: false
  },
  DualCFGGuider: {
    input: {
      required: {
        model: ['MODEL'],
        cond1: ['CONDITIONING'],
        cond2: ['CONDITIONING'],
        negative: ['CONDITIONING'],
        cfg_conds: [
          'FLOAT',
          {
            default: 8,
            min: 0,
            max: 100,
            step: 0.1,
            round: 0.01
          }
        ],
        cfg_cond2_negative: [
          'FLOAT',
          {
            default: 8,
            min: 0,
            max: 100,
            step: 0.1,
            round: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'model',
        'cond1',
        'cond2',
        'negative',
        'cfg_conds',
        'cfg_cond2_negative'
      ]
    },
    output: ['GUIDER'],
    output_is_list: [false],
    output_name: ['GUIDER'],
    name: 'DualCFGGuider',
    display_name: 'DualCFGGuider',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/guiders',
    output_node: false
  },
  BasicGuider: {
    input: {
      required: {
        model: ['MODEL'],
        conditioning: ['CONDITIONING']
      }
    },
    input_order: {
      required: ['model', 'conditioning']
    },
    output: ['GUIDER'],
    output_is_list: [false],
    output_name: ['GUIDER'],
    name: 'BasicGuider',
    display_name: 'BasicGuider',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/guiders',
    output_node: false
  },
  RandomNoise: {
    input: {
      required: {
        noise_seed: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 18446744073709552000
          }
        ]
      }
    },
    input_order: {
      required: ['noise_seed']
    },
    output: ['NOISE'],
    output_is_list: [false],
    output_name: ['NOISE'],
    name: 'RandomNoise',
    display_name: 'RandomNoise',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/noise',
    output_node: false
  },
  DisableNoise: {
    input: {
      required: {}
    },
    input_order: {
      required: []
    },
    output: ['NOISE'],
    output_is_list: [false],
    output_name: ['NOISE'],
    name: 'DisableNoise',
    display_name: 'DisableNoise',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling/noise',
    output_node: false
  },
  AddNoise: {
    input: {
      required: {
        model: ['MODEL'],
        noise: ['NOISE'],
        sigmas: ['SIGMAS'],
        latent_image: ['LATENT']
      }
    },
    input_order: {
      required: ['model', 'noise', 'sigmas', 'latent_image']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'AddNoise',
    display_name: 'AddNoise',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: '_for_testing/custom_sampling/noise',
    output_node: false
  },
  SamplerCustomAdvanced: {
    input: {
      required: {
        noise: ['NOISE'],
        guider: ['GUIDER'],
        sampler: ['SAMPLER'],
        sigmas: ['SIGMAS'],
        latent_image: ['LATENT']
      }
    },
    input_order: {
      required: ['noise', 'guider', 'sampler', 'sigmas', 'latent_image']
    },
    output: ['LATENT', 'LATENT'],
    output_is_list: [false, false],
    output_name: ['output', 'denoised_output'],
    name: 'SamplerCustomAdvanced',
    display_name: 'SamplerCustomAdvanced',
    description: '',
    python_module: 'comfy_extras.nodes_custom_sampler',
    category: 'sampling/custom_sampling',
    output_node: false
  },
  HyperTile: {
    input: {
      required: {
        model: ['MODEL'],
        tile_size: [
          'INT',
          {
            default: 256,
            min: 1,
            max: 2048
          }
        ],
        swap_size: [
          'INT',
          {
            default: 2,
            min: 1,
            max: 128
          }
        ],
        max_depth: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 10
          }
        ],
        scale_depth: [
          'BOOLEAN',
          {
            default: false
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'tile_size', 'swap_size', 'max_depth', 'scale_depth']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'HyperTile',
    display_name: 'HyperTile',
    description: '',
    python_module: 'comfy_extras.nodes_hypertile',
    category: 'model_patches/unet',
    output_node: false
  },
  ModelSamplingDiscrete: {
    input: {
      required: {
        model: ['MODEL'],
        sampling: [['eps', 'v_prediction', 'lcm', 'x0']],
        zsnr: [
          'BOOLEAN',
          {
            default: false
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'sampling', 'zsnr']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelSamplingDiscrete',
    display_name: 'ModelSamplingDiscrete',
    description: '',
    python_module: 'comfy_extras.nodes_model_advanced',
    category: 'advanced/model',
    output_node: false
  },
  ModelSamplingContinuousEDM: {
    input: {
      required: {
        model: ['MODEL'],
        sampling: [['v_prediction', 'edm_playground_v2.5', 'eps']],
        sigma_max: [
          'FLOAT',
          {
            default: 120,
            min: 0,
            max: 1000,
            step: 0.001,
            round: false
          }
        ],
        sigma_min: [
          'FLOAT',
          {
            default: 0.002,
            min: 0,
            max: 1000,
            step: 0.001,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'sampling', 'sigma_max', 'sigma_min']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelSamplingContinuousEDM',
    display_name: 'ModelSamplingContinuousEDM',
    description: '',
    python_module: 'comfy_extras.nodes_model_advanced',
    category: 'advanced/model',
    output_node: false
  },
  ModelSamplingContinuousV: {
    input: {
      required: {
        model: ['MODEL'],
        sampling: [['v_prediction']],
        sigma_max: [
          'FLOAT',
          {
            default: 500,
            min: 0,
            max: 1000,
            step: 0.001,
            round: false
          }
        ],
        sigma_min: [
          'FLOAT',
          {
            default: 0.03,
            min: 0,
            max: 1000,
            step: 0.001,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'sampling', 'sigma_max', 'sigma_min']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelSamplingContinuousV',
    display_name: 'ModelSamplingContinuousV',
    description: '',
    python_module: 'comfy_extras.nodes_model_advanced',
    category: 'advanced/model',
    output_node: false
  },
  ModelSamplingStableCascade: {
    input: {
      required: {
        model: ['MODEL'],
        shift: [
          'FLOAT',
          {
            default: 2,
            min: 0,
            max: 100,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'shift']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelSamplingStableCascade',
    display_name: 'ModelSamplingStableCascade',
    description: '',
    python_module: 'comfy_extras.nodes_model_advanced',
    category: 'advanced/model',
    output_node: false
  },
  ModelSamplingSD3: {
    input: {
      required: {
        model: ['MODEL'],
        shift: [
          'FLOAT',
          {
            default: 3,
            min: 0,
            max: 100,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'shift']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelSamplingSD3',
    display_name: 'ModelSamplingSD3',
    description: '',
    python_module: 'comfy_extras.nodes_model_advanced',
    category: 'advanced/model',
    output_node: false
  },
  ModelSamplingAuraFlow: {
    input: {
      required: {
        model: ['MODEL'],
        shift: [
          'FLOAT',
          {
            default: 1.73,
            min: 0,
            max: 100,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'shift']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelSamplingAuraFlow',
    display_name: 'ModelSamplingAuraFlow',
    description: '',
    python_module: 'comfy_extras.nodes_model_advanced',
    category: 'advanced/model',
    output_node: false
  },
  ModelSamplingFlux: {
    input: {
      required: {
        model: ['MODEL'],
        max_shift: [
          'FLOAT',
          {
            default: 1.15,
            min: 0,
            max: 100,
            step: 0.01
          }
        ],
        base_shift: [
          'FLOAT',
          {
            default: 0.5,
            min: 0,
            max: 100,
            step: 0.01
          }
        ],
        width: [
          'INT',
          {
            default: 1024,
            min: 16,
            max: 16384,
            step: 8
          }
        ],
        height: [
          'INT',
          {
            default: 1024,
            min: 16,
            max: 16384,
            step: 8
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'max_shift', 'base_shift', 'width', 'height']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelSamplingFlux',
    display_name: 'ModelSamplingFlux',
    description: '',
    python_module: 'comfy_extras.nodes_model_advanced',
    category: 'advanced/model',
    output_node: false
  },
  RescaleCFG: {
    input: {
      required: {
        model: ['MODEL'],
        multiplier: [
          'FLOAT',
          {
            default: 0.7,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'multiplier']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'RescaleCFG',
    display_name: 'RescaleCFG',
    description: '',
    python_module: 'comfy_extras.nodes_model_advanced',
    category: 'advanced/model',
    output_node: false
  },
  PatchModelAddDownscale: {
    input: {
      required: {
        model: ['MODEL'],
        block_number: [
          'INT',
          {
            default: 3,
            min: 1,
            max: 32,
            step: 1
          }
        ],
        downscale_factor: [
          'FLOAT',
          {
            default: 2,
            min: 0.1,
            max: 9,
            step: 0.001
          }
        ],
        start_percent: [
          'FLOAT',
          {
            default: 0,
            min: 0,
            max: 1,
            step: 0.001
          }
        ],
        end_percent: [
          'FLOAT',
          {
            default: 0.35,
            min: 0,
            max: 1,
            step: 0.001
          }
        ],
        downscale_after_skip: [
          'BOOLEAN',
          {
            default: true
          }
        ],
        downscale_method: [
          ['bicubic', 'nearest-exact', 'bilinear', 'area', 'bislerp']
        ],
        upscale_method: [
          ['bicubic', 'nearest-exact', 'bilinear', 'area', 'bislerp']
        ]
      }
    },
    input_order: {
      required: [
        'model',
        'block_number',
        'downscale_factor',
        'start_percent',
        'end_percent',
        'downscale_after_skip',
        'downscale_method',
        'upscale_method'
      ]
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'PatchModelAddDownscale',
    display_name: 'PatchModelAddDownscale (Kohya Deep Shrink)',
    description: '',
    python_module: 'comfy_extras.nodes_model_downscale',
    category: 'model_patches/unet',
    output_node: false
  },
  ImageCrop: {
    input: {
      required: {
        image: ['IMAGE'],
        width: [
          'INT',
          {
            default: 512,
            min: 1,
            max: 16384,
            step: 1
          }
        ],
        height: [
          'INT',
          {
            default: 512,
            min: 1,
            max: 16384,
            step: 1
          }
        ],
        x: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        y: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ]
      }
    },
    input_order: {
      required: ['image', 'width', 'height', 'x', 'y']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'ImageCrop',
    display_name: 'Image Crop',
    description: '',
    python_module: 'comfy_extras.nodes_images',
    category: 'image/transform',
    output_node: false
  },
  RepeatImageBatch: {
    input: {
      required: {
        image: ['IMAGE'],
        amount: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096
          }
        ]
      }
    },
    input_order: {
      required: ['image', 'amount']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'RepeatImageBatch',
    display_name: 'RepeatImageBatch',
    description: '',
    python_module: 'comfy_extras.nodes_images',
    category: 'image/batch',
    output_node: false
  },
  ImageFromBatch: {
    input: {
      required: {
        image: ['IMAGE'],
        batch_index: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 4095
          }
        ],
        length: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096
          }
        ]
      }
    },
    input_order: {
      required: ['image', 'batch_index', 'length']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'ImageFromBatch',
    display_name: 'ImageFromBatch',
    description: '',
    python_module: 'comfy_extras.nodes_images',
    category: 'image/batch',
    output_node: false
  },
  SaveAnimatedWEBP: {
    input: {
      required: {
        images: ['IMAGE'],
        filename_prefix: [
          'STRING',
          {
            default: 'ComfyUI'
          }
        ],
        fps: [
          'FLOAT',
          {
            default: 6,
            min: 0.01,
            max: 1000,
            step: 0.01
          }
        ],
        lossless: [
          'BOOLEAN',
          {
            default: true
          }
        ],
        quality: [
          'INT',
          {
            default: 80,
            min: 0,
            max: 100
          }
        ],
        method: [['default', 'fastest', 'slowest']]
      },
      hidden: {
        prompt: 'PROMPT',
        extra_pnginfo: 'EXTRA_PNGINFO'
      }
    },
    input_order: {
      required: [
        'images',
        'filename_prefix',
        'fps',
        'lossless',
        'quality',
        'method'
      ],
      hidden: ['prompt', 'extra_pnginfo']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'SaveAnimatedWEBP',
    display_name: 'SaveAnimatedWEBP',
    description: '',
    python_module: 'comfy_extras.nodes_images',
    category: 'image/animation',
    output_node: true
  },
  SaveAnimatedPNG: {
    input: {
      required: {
        images: ['IMAGE'],
        filename_prefix: [
          'STRING',
          {
            default: 'ComfyUI'
          }
        ],
        fps: [
          'FLOAT',
          {
            default: 6,
            min: 0.01,
            max: 1000,
            step: 0.01
          }
        ],
        compress_level: [
          'INT',
          {
            default: 4,
            min: 0,
            max: 9
          }
        ]
      },
      hidden: {
        prompt: 'PROMPT',
        extra_pnginfo: 'EXTRA_PNGINFO'
      }
    },
    input_order: {
      required: ['images', 'filename_prefix', 'fps', 'compress_level'],
      hidden: ['prompt', 'extra_pnginfo']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'SaveAnimatedPNG',
    display_name: 'SaveAnimatedPNG',
    description: '',
    python_module: 'comfy_extras.nodes_images',
    category: 'image/animation',
    output_node: true
  },
  ImageOnlyCheckpointLoader: {
    input: {
      required: {
        ckpt_name: [['Realistic_Vision_V5.0.safetensors']]
      }
    },
    input_order: {
      required: ['ckpt_name']
    },
    output: ['MODEL', 'CLIP_VISION', 'VAE'],
    output_is_list: [false, false, false],
    output_name: ['MODEL', 'CLIP_VISION', 'VAE'],
    name: 'ImageOnlyCheckpointLoader',
    display_name: 'Image Only Checkpoint Loader (img2vid model)',
    description: '',
    python_module: 'comfy_extras.nodes_video_model',
    category: 'loaders/video_models',
    output_node: false
  },
  SVD_img2vid_Conditioning: {
    input: {
      required: {
        clip_vision: ['CLIP_VISION'],
        init_image: ['IMAGE'],
        vae: ['VAE'],
        width: [
          'INT',
          {
            default: 1024,
            min: 16,
            max: 16384,
            step: 8
          }
        ],
        height: [
          'INT',
          {
            default: 576,
            min: 16,
            max: 16384,
            step: 8
          }
        ],
        video_frames: [
          'INT',
          {
            default: 14,
            min: 1,
            max: 4096
          }
        ],
        motion_bucket_id: [
          'INT',
          {
            default: 127,
            min: 1,
            max: 1023
          }
        ],
        fps: [
          'INT',
          {
            default: 6,
            min: 1,
            max: 1024
          }
        ],
        augmentation_level: [
          'FLOAT',
          {
            default: 0,
            min: 0,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'clip_vision',
        'init_image',
        'vae',
        'width',
        'height',
        'video_frames',
        'motion_bucket_id',
        'fps',
        'augmentation_level'
      ]
    },
    output: ['CONDITIONING', 'CONDITIONING', 'LATENT'],
    output_is_list: [false, false, false],
    output_name: ['positive', 'negative', 'latent'],
    name: 'SVD_img2vid_Conditioning',
    display_name: 'SVD_img2vid_Conditioning',
    description: '',
    python_module: 'comfy_extras.nodes_video_model',
    category: 'conditioning/video_models',
    output_node: false
  },
  VideoLinearCFGGuidance: {
    input: {
      required: {
        model: ['MODEL'],
        min_cfg: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.5,
            round: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'min_cfg']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'VideoLinearCFGGuidance',
    display_name: 'VideoLinearCFGGuidance',
    description: '',
    python_module: 'comfy_extras.nodes_video_model',
    category: 'sampling/video_models',
    output_node: false
  },
  VideoTriangleCFGGuidance: {
    input: {
      required: {
        model: ['MODEL'],
        min_cfg: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.5,
            round: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'min_cfg']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'VideoTriangleCFGGuidance',
    display_name: 'VideoTriangleCFGGuidance',
    description: '',
    python_module: 'comfy_extras.nodes_video_model',
    category: 'sampling/video_models',
    output_node: false
  },
  ImageOnlyCheckpointSave: {
    input: {
      required: {
        model: ['MODEL'],
        clip_vision: ['CLIP_VISION'],
        vae: ['VAE'],
        filename_prefix: [
          'STRING',
          {
            default: 'checkpoints/ComfyUI'
          }
        ]
      },
      hidden: {
        prompt: 'PROMPT',
        extra_pnginfo: 'EXTRA_PNGINFO'
      }
    },
    input_order: {
      required: ['model', 'clip_vision', 'vae', 'filename_prefix'],
      hidden: ['prompt', 'extra_pnginfo']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'ImageOnlyCheckpointSave',
    display_name: 'ImageOnlyCheckpointSave',
    description: '',
    python_module: 'comfy_extras.nodes_video_model',
    category: 'advanced/model_merging',
    output_node: true
  },
  SelfAttentionGuidance: {
    input: {
      required: {
        model: ['MODEL'],
        scale: [
          'FLOAT',
          {
            default: 0.5,
            min: -2,
            max: 5,
            step: 0.01
          }
        ],
        blur_sigma: [
          'FLOAT',
          {
            default: 2,
            min: 0,
            max: 10,
            step: 0.1
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'scale', 'blur_sigma']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'SelfAttentionGuidance',
    display_name: 'Self-Attention Guidance',
    description: '',
    python_module: 'comfy_extras.nodes_sag',
    category: '_for_testing',
    output_node: false
  },
  PerpNeg: {
    input: {
      required: {
        model: ['MODEL'],
        empty_conditioning: ['CONDITIONING'],
        neg_scale: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'empty_conditioning', 'neg_scale']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'PerpNeg',
    display_name: 'Perp-Neg (DEPRECATED by PerpNegGuider)',
    description: '',
    python_module: 'comfy_extras.nodes_perpneg',
    category: '_for_testing',
    output_node: false,
    deprecated: true
  },
  PerpNegGuider: {
    input: {
      required: {
        model: ['MODEL'],
        positive: ['CONDITIONING'],
        negative: ['CONDITIONING'],
        empty_conditioning: ['CONDITIONING'],
        cfg: [
          'FLOAT',
          {
            default: 8,
            min: 0,
            max: 100,
            step: 0.1,
            round: 0.01
          }
        ],
        neg_scale: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 100,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'model',
        'positive',
        'negative',
        'empty_conditioning',
        'cfg',
        'neg_scale'
      ]
    },
    output: ['GUIDER'],
    output_is_list: [false],
    output_name: ['GUIDER'],
    name: 'PerpNegGuider',
    display_name: 'PerpNegGuider',
    description: '',
    python_module: 'comfy_extras.nodes_perpneg',
    category: '_for_testing',
    output_node: false
  },
  StableZero123_Conditioning: {
    input: {
      required: {
        clip_vision: ['CLIP_VISION'],
        init_image: ['IMAGE'],
        vae: ['VAE'],
        width: [
          'INT',
          {
            default: 256,
            min: 16,
            max: 16384,
            step: 8
          }
        ],
        height: [
          'INT',
          {
            default: 256,
            min: 16,
            max: 16384,
            step: 8
          }
        ],
        batch_size: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096
          }
        ],
        elevation: [
          'FLOAT',
          {
            default: 0,
            min: -180,
            max: 180,
            step: 0.1,
            round: false
          }
        ],
        azimuth: [
          'FLOAT',
          {
            default: 0,
            min: -180,
            max: 180,
            step: 0.1,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: [
        'clip_vision',
        'init_image',
        'vae',
        'width',
        'height',
        'batch_size',
        'elevation',
        'azimuth'
      ]
    },
    output: ['CONDITIONING', 'CONDITIONING', 'LATENT'],
    output_is_list: [false, false, false],
    output_name: ['positive', 'negative', 'latent'],
    name: 'StableZero123_Conditioning',
    display_name: 'StableZero123_Conditioning',
    description: '',
    python_module: 'comfy_extras.nodes_stable3d',
    category: 'conditioning/3d_models',
    output_node: false
  },
  StableZero123_Conditioning_Batched: {
    input: {
      required: {
        clip_vision: ['CLIP_VISION'],
        init_image: ['IMAGE'],
        vae: ['VAE'],
        width: [
          'INT',
          {
            default: 256,
            min: 16,
            max: 16384,
            step: 8
          }
        ],
        height: [
          'INT',
          {
            default: 256,
            min: 16,
            max: 16384,
            step: 8
          }
        ],
        batch_size: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096
          }
        ],
        elevation: [
          'FLOAT',
          {
            default: 0,
            min: -180,
            max: 180,
            step: 0.1,
            round: false
          }
        ],
        azimuth: [
          'FLOAT',
          {
            default: 0,
            min: -180,
            max: 180,
            step: 0.1,
            round: false
          }
        ],
        elevation_batch_increment: [
          'FLOAT',
          {
            default: 0,
            min: -180,
            max: 180,
            step: 0.1,
            round: false
          }
        ],
        azimuth_batch_increment: [
          'FLOAT',
          {
            default: 0,
            min: -180,
            max: 180,
            step: 0.1,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: [
        'clip_vision',
        'init_image',
        'vae',
        'width',
        'height',
        'batch_size',
        'elevation',
        'azimuth',
        'elevation_batch_increment',
        'azimuth_batch_increment'
      ]
    },
    output: ['CONDITIONING', 'CONDITIONING', 'LATENT'],
    output_is_list: [false, false, false],
    output_name: ['positive', 'negative', 'latent'],
    name: 'StableZero123_Conditioning_Batched',
    display_name: 'StableZero123_Conditioning_Batched',
    description: '',
    python_module: 'comfy_extras.nodes_stable3d',
    category: 'conditioning/3d_models',
    output_node: false
  },
  SV3D_Conditioning: {
    input: {
      required: {
        clip_vision: ['CLIP_VISION'],
        init_image: ['IMAGE'],
        vae: ['VAE'],
        width: [
          'INT',
          {
            default: 576,
            min: 16,
            max: 16384,
            step: 8
          }
        ],
        height: [
          'INT',
          {
            default: 576,
            min: 16,
            max: 16384,
            step: 8
          }
        ],
        video_frames: [
          'INT',
          {
            default: 21,
            min: 1,
            max: 4096
          }
        ],
        elevation: [
          'FLOAT',
          {
            default: 0,
            min: -90,
            max: 90,
            step: 0.1,
            round: false
          }
        ]
      }
    },
    input_order: {
      required: [
        'clip_vision',
        'init_image',
        'vae',
        'width',
        'height',
        'video_frames',
        'elevation'
      ]
    },
    output: ['CONDITIONING', 'CONDITIONING', 'LATENT'],
    output_is_list: [false, false, false],
    output_name: ['positive', 'negative', 'latent'],
    name: 'SV3D_Conditioning',
    display_name: 'SV3D_Conditioning',
    description: '',
    python_module: 'comfy_extras.nodes_stable3d',
    category: 'conditioning/3d_models',
    output_node: false
  },
  SD_4XUpscale_Conditioning: {
    input: {
      required: {
        images: ['IMAGE'],
        positive: ['CONDITIONING'],
        negative: ['CONDITIONING'],
        scale_ratio: [
          'FLOAT',
          {
            default: 4,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        noise_augmentation: [
          'FLOAT',
          {
            default: 0,
            min: 0,
            max: 1,
            step: 0.001
          }
        ]
      }
    },
    input_order: {
      required: [
        'images',
        'positive',
        'negative',
        'scale_ratio',
        'noise_augmentation'
      ]
    },
    output: ['CONDITIONING', 'CONDITIONING', 'LATENT'],
    output_is_list: [false, false, false],
    output_name: ['positive', 'negative', 'latent'],
    name: 'SD_4XUpscale_Conditioning',
    display_name: 'SD_4XUpscale_Conditioning',
    description: '',
    python_module: 'comfy_extras.nodes_sdupscale',
    category: 'conditioning/upscale_diffusion',
    output_node: false
  },
  PhotoMakerLoader: {
    input: {
      required: {
        photomaker_model_name: [[]]
      }
    },
    input_order: {
      required: ['photomaker_model_name']
    },
    output: ['PHOTOMAKER'],
    output_is_list: [false],
    output_name: ['PHOTOMAKER'],
    name: 'PhotoMakerLoader',
    display_name: 'PhotoMakerLoader',
    description: '',
    python_module: 'comfy_extras.nodes_photomaker',
    category: '_for_testing/photomaker',
    output_node: false
  },
  PhotoMakerEncode: {
    input: {
      required: {
        photomaker: ['PHOTOMAKER'],
        image: ['IMAGE'],
        clip: ['CLIP'],
        text: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true,
            default: 'photograph of photomaker'
          }
        ]
      }
    },
    input_order: {
      required: ['photomaker', 'image', 'clip', 'text']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'PhotoMakerEncode',
    display_name: 'PhotoMakerEncode',
    description: '',
    python_module: 'comfy_extras.nodes_photomaker',
    category: '_for_testing/photomaker',
    output_node: false
  },
  CLIPTextEncodeControlnet: {
    input: {
      required: {
        clip: ['CLIP'],
        conditioning: ['CONDITIONING'],
        text: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true
          }
        ]
      }
    },
    input_order: {
      required: ['clip', 'conditioning', 'text']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'CLIPTextEncodeControlnet',
    display_name: 'CLIPTextEncodeControlnet',
    description: '',
    python_module: 'comfy_extras.nodes_cond',
    category: '_for_testing/conditioning',
    output_node: false
  },
  Morphology: {
    input: {
      required: {
        image: ['IMAGE'],
        operation: [
          [
            'erode',
            'dilate',
            'open',
            'close',
            'gradient',
            'bottom_hat',
            'top_hat'
          ]
        ],
        kernel_size: [
          'INT',
          {
            default: 3,
            min: 3,
            max: 999,
            step: 1
          }
        ]
      }
    },
    input_order: {
      required: ['image', 'operation', 'kernel_size']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'Morphology',
    display_name: 'ImageMorphology',
    description: '',
    python_module: 'comfy_extras.nodes_morphology',
    category: 'image/postprocessing',
    output_node: false
  },
  StableCascade_EmptyLatentImage: {
    input: {
      required: {
        width: [
          'INT',
          {
            default: 1024,
            min: 256,
            max: 16384,
            step: 8
          }
        ],
        height: [
          'INT',
          {
            default: 1024,
            min: 256,
            max: 16384,
            step: 8
          }
        ],
        compression: [
          'INT',
          {
            default: 42,
            min: 4,
            max: 128,
            step: 1
          }
        ],
        batch_size: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096
          }
        ]
      }
    },
    input_order: {
      required: ['width', 'height', 'compression', 'batch_size']
    },
    output: ['LATENT', 'LATENT'],
    output_is_list: [false, false],
    output_name: ['stage_c', 'stage_b'],
    name: 'StableCascade_EmptyLatentImage',
    display_name: 'StableCascade_EmptyLatentImage',
    description: '',
    python_module: 'comfy_extras.nodes_stable_cascade',
    category: 'latent/stable_cascade',
    output_node: false
  },
  StableCascade_StageB_Conditioning: {
    input: {
      required: {
        conditioning: ['CONDITIONING'],
        stage_c: ['LATENT']
      }
    },
    input_order: {
      required: ['conditioning', 'stage_c']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'StableCascade_StageB_Conditioning',
    display_name: 'StableCascade_StageB_Conditioning',
    description: '',
    python_module: 'comfy_extras.nodes_stable_cascade',
    category: 'conditioning/stable_cascade',
    output_node: false
  },
  StableCascade_StageC_VAEEncode: {
    input: {
      required: {
        image: ['IMAGE'],
        vae: ['VAE'],
        compression: [
          'INT',
          {
            default: 42,
            min: 4,
            max: 128,
            step: 1
          }
        ]
      }
    },
    input_order: {
      required: ['image', 'vae', 'compression']
    },
    output: ['LATENT', 'LATENT'],
    output_is_list: [false, false],
    output_name: ['stage_c', 'stage_b'],
    name: 'StableCascade_StageC_VAEEncode',
    display_name: 'StableCascade_StageC_VAEEncode',
    description: '',
    python_module: 'comfy_extras.nodes_stable_cascade',
    category: 'latent/stable_cascade',
    output_node: false
  },
  StableCascade_SuperResolutionControlnet: {
    input: {
      required: {
        image: ['IMAGE'],
        vae: ['VAE']
      }
    },
    input_order: {
      required: ['image', 'vae']
    },
    output: ['IMAGE', 'LATENT', 'LATENT'],
    output_is_list: [false, false, false],
    output_name: ['controlnet_input', 'stage_c', 'stage_b'],
    name: 'StableCascade_SuperResolutionControlnet',
    display_name: 'StableCascade_SuperResolutionControlnet',
    description: '',
    python_module: 'comfy_extras.nodes_stable_cascade',
    category: '_for_testing/stable_cascade',
    output_node: false,
    experimental: true
  },
  DifferentialDiffusion: {
    input: {
      required: {
        model: ['MODEL']
      }
    },
    input_order: {
      required: ['model']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'DifferentialDiffusion',
    display_name: 'Differential Diffusion',
    description: '',
    python_module: 'comfy_extras.nodes_differential_diffusion',
    category: '_for_testing',
    output_node: false
  },
  InstructPixToPixConditioning: {
    input: {
      required: {
        positive: ['CONDITIONING'],
        negative: ['CONDITIONING'],
        vae: ['VAE'],
        pixels: ['IMAGE']
      }
    },
    input_order: {
      required: ['positive', 'negative', 'vae', 'pixels']
    },
    output: ['CONDITIONING', 'CONDITIONING', 'LATENT'],
    output_is_list: [false, false, false],
    output_name: ['positive', 'negative', 'latent'],
    name: 'InstructPixToPixConditioning',
    display_name: 'InstructPixToPixConditioning',
    description: '',
    python_module: 'comfy_extras.nodes_ip2p',
    category: 'conditioning/instructpix2pix',
    output_node: false
  },
  ModelMergeSD1: {
    input: {
      required: {
        model1: ['MODEL'],
        model2: ['MODEL'],
        'time_embed.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'label_emb.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.3.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.4.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.5.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.6.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.7.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.8.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.9.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.10.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.11.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'middle_block.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'middle_block.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'middle_block.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.3.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.4.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.5.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.6.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.7.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.8.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.9.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.10.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.11.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'out.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'model1',
        'model2',
        'time_embed.',
        'label_emb.',
        'input_blocks.0.',
        'input_blocks.1.',
        'input_blocks.2.',
        'input_blocks.3.',
        'input_blocks.4.',
        'input_blocks.5.',
        'input_blocks.6.',
        'input_blocks.7.',
        'input_blocks.8.',
        'input_blocks.9.',
        'input_blocks.10.',
        'input_blocks.11.',
        'middle_block.0.',
        'middle_block.1.',
        'middle_block.2.',
        'output_blocks.0.',
        'output_blocks.1.',
        'output_blocks.2.',
        'output_blocks.3.',
        'output_blocks.4.',
        'output_blocks.5.',
        'output_blocks.6.',
        'output_blocks.7.',
        'output_blocks.8.',
        'output_blocks.9.',
        'output_blocks.10.',
        'output_blocks.11.',
        'out.'
      ]
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelMergeSD1',
    display_name: 'ModelMergeSD1',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging_model_specific',
    category: 'advanced/model_merging/model_specific',
    output_node: false
  },
  ModelMergeSD2: {
    input: {
      required: {
        model1: ['MODEL'],
        model2: ['MODEL'],
        'time_embed.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'label_emb.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.3.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.4.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.5.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.6.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.7.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.8.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.9.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.10.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.11.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'middle_block.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'middle_block.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'middle_block.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.3.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.4.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.5.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.6.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.7.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.8.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.9.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.10.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.11.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'out.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'model1',
        'model2',
        'time_embed.',
        'label_emb.',
        'input_blocks.0.',
        'input_blocks.1.',
        'input_blocks.2.',
        'input_blocks.3.',
        'input_blocks.4.',
        'input_blocks.5.',
        'input_blocks.6.',
        'input_blocks.7.',
        'input_blocks.8.',
        'input_blocks.9.',
        'input_blocks.10.',
        'input_blocks.11.',
        'middle_block.0.',
        'middle_block.1.',
        'middle_block.2.',
        'output_blocks.0.',
        'output_blocks.1.',
        'output_blocks.2.',
        'output_blocks.3.',
        'output_blocks.4.',
        'output_blocks.5.',
        'output_blocks.6.',
        'output_blocks.7.',
        'output_blocks.8.',
        'output_blocks.9.',
        'output_blocks.10.',
        'output_blocks.11.',
        'out.'
      ]
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelMergeSD2',
    display_name: 'ModelMergeSD2',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging_model_specific',
    category: 'advanced/model_merging/model_specific',
    output_node: false
  },
  ModelMergeSDXL: {
    input: {
      required: {
        model1: ['MODEL'],
        model2: ['MODEL'],
        'time_embed.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'label_emb.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.0': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.1': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.2': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.3': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.4': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.5': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.6': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.7': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'input_blocks.8': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'middle_block.0': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'middle_block.1': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'middle_block.2': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.0': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.1': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.2': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.3': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.4': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.5': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.6': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.7': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'output_blocks.8': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'out.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'model1',
        'model2',
        'time_embed.',
        'label_emb.',
        'input_blocks.0',
        'input_blocks.1',
        'input_blocks.2',
        'input_blocks.3',
        'input_blocks.4',
        'input_blocks.5',
        'input_blocks.6',
        'input_blocks.7',
        'input_blocks.8',
        'middle_block.0',
        'middle_block.1',
        'middle_block.2',
        'output_blocks.0',
        'output_blocks.1',
        'output_blocks.2',
        'output_blocks.3',
        'output_blocks.4',
        'output_blocks.5',
        'output_blocks.6',
        'output_blocks.7',
        'output_blocks.8',
        'out.'
      ]
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelMergeSDXL',
    display_name: 'ModelMergeSDXL',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging_model_specific',
    category: 'advanced/model_merging/model_specific',
    output_node: false
  },
  ModelMergeSD3_2B: {
    input: {
      required: {
        model1: ['MODEL'],
        model2: ['MODEL'],
        'pos_embed.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'x_embedder.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'context_embedder.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'y_embedder.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        't_embedder.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.3.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.4.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.5.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.6.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.7.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.8.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.9.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.10.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.11.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.12.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.13.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.14.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.15.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.16.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.17.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.18.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.19.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.20.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.21.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.22.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.23.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'final_layer.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'model1',
        'model2',
        'pos_embed.',
        'x_embedder.',
        'context_embedder.',
        'y_embedder.',
        't_embedder.',
        'joint_blocks.0.',
        'joint_blocks.1.',
        'joint_blocks.2.',
        'joint_blocks.3.',
        'joint_blocks.4.',
        'joint_blocks.5.',
        'joint_blocks.6.',
        'joint_blocks.7.',
        'joint_blocks.8.',
        'joint_blocks.9.',
        'joint_blocks.10.',
        'joint_blocks.11.',
        'joint_blocks.12.',
        'joint_blocks.13.',
        'joint_blocks.14.',
        'joint_blocks.15.',
        'joint_blocks.16.',
        'joint_blocks.17.',
        'joint_blocks.18.',
        'joint_blocks.19.',
        'joint_blocks.20.',
        'joint_blocks.21.',
        'joint_blocks.22.',
        'joint_blocks.23.',
        'final_layer.'
      ]
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelMergeSD3_2B',
    display_name: 'ModelMergeSD3_2B',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging_model_specific',
    category: 'advanced/model_merging/model_specific',
    output_node: false
  },
  ModelMergeAuraflow: {
    input: {
      required: {
        model1: ['MODEL'],
        model2: ['MODEL'],
        'init_x_linear.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        positional_encoding: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'cond_seq_linear.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        register_tokens: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        't_embedder.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_layers.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_layers.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_layers.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_layers.3.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.3.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.4.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.5.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.6.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.7.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.8.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.9.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.10.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.11.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.12.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.13.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.14.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.15.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.16.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.17.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.18.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.19.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.20.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.21.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.22.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.23.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.24.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.25.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.26.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.27.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.28.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.29.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.30.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_layers.31.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'modF.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'final_linear.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'model1',
        'model2',
        'init_x_linear.',
        'positional_encoding',
        'cond_seq_linear.',
        'register_tokens',
        't_embedder.',
        'double_layers.0.',
        'double_layers.1.',
        'double_layers.2.',
        'double_layers.3.',
        'single_layers.0.',
        'single_layers.1.',
        'single_layers.2.',
        'single_layers.3.',
        'single_layers.4.',
        'single_layers.5.',
        'single_layers.6.',
        'single_layers.7.',
        'single_layers.8.',
        'single_layers.9.',
        'single_layers.10.',
        'single_layers.11.',
        'single_layers.12.',
        'single_layers.13.',
        'single_layers.14.',
        'single_layers.15.',
        'single_layers.16.',
        'single_layers.17.',
        'single_layers.18.',
        'single_layers.19.',
        'single_layers.20.',
        'single_layers.21.',
        'single_layers.22.',
        'single_layers.23.',
        'single_layers.24.',
        'single_layers.25.',
        'single_layers.26.',
        'single_layers.27.',
        'single_layers.28.',
        'single_layers.29.',
        'single_layers.30.',
        'single_layers.31.',
        'modF.',
        'final_linear.'
      ]
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelMergeAuraflow',
    display_name: 'ModelMergeAuraflow',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging_model_specific',
    category: 'advanced/model_merging/model_specific',
    output_node: false
  },
  ModelMergeFlux1: {
    input: {
      required: {
        model1: ['MODEL'],
        model2: ['MODEL'],
        'img_in.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'time_in.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        guidance_in: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'vector_in.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'txt_in.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.3.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.4.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.5.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.6.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.7.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.8.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.9.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.10.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.11.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.12.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.13.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.14.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.15.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.16.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.17.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'double_blocks.18.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.3.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.4.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.5.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.6.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.7.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.8.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.9.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.10.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.11.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.12.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.13.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.14.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.15.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.16.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.17.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.18.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.19.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.20.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.21.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.22.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.23.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.24.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.25.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.26.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.27.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.28.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.29.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.30.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.31.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.32.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.33.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.34.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.35.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.36.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'single_blocks.37.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'final_layer.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'model1',
        'model2',
        'img_in.',
        'time_in.',
        'guidance_in',
        'vector_in.',
        'txt_in.',
        'double_blocks.0.',
        'double_blocks.1.',
        'double_blocks.2.',
        'double_blocks.3.',
        'double_blocks.4.',
        'double_blocks.5.',
        'double_blocks.6.',
        'double_blocks.7.',
        'double_blocks.8.',
        'double_blocks.9.',
        'double_blocks.10.',
        'double_blocks.11.',
        'double_blocks.12.',
        'double_blocks.13.',
        'double_blocks.14.',
        'double_blocks.15.',
        'double_blocks.16.',
        'double_blocks.17.',
        'double_blocks.18.',
        'single_blocks.0.',
        'single_blocks.1.',
        'single_blocks.2.',
        'single_blocks.3.',
        'single_blocks.4.',
        'single_blocks.5.',
        'single_blocks.6.',
        'single_blocks.7.',
        'single_blocks.8.',
        'single_blocks.9.',
        'single_blocks.10.',
        'single_blocks.11.',
        'single_blocks.12.',
        'single_blocks.13.',
        'single_blocks.14.',
        'single_blocks.15.',
        'single_blocks.16.',
        'single_blocks.17.',
        'single_blocks.18.',
        'single_blocks.19.',
        'single_blocks.20.',
        'single_blocks.21.',
        'single_blocks.22.',
        'single_blocks.23.',
        'single_blocks.24.',
        'single_blocks.25.',
        'single_blocks.26.',
        'single_blocks.27.',
        'single_blocks.28.',
        'single_blocks.29.',
        'single_blocks.30.',
        'single_blocks.31.',
        'single_blocks.32.',
        'single_blocks.33.',
        'single_blocks.34.',
        'single_blocks.35.',
        'single_blocks.36.',
        'single_blocks.37.',
        'final_layer.'
      ]
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelMergeFlux1',
    display_name: 'ModelMergeFlux1',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging_model_specific',
    category: 'advanced/model_merging/model_specific',
    output_node: false
  },
  ModelMergeSD35_Large: {
    input: {
      required: {
        model1: ['MODEL'],
        model2: ['MODEL'],
        'pos_embed.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'x_embedder.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'context_embedder.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'y_embedder.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        't_embedder.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.3.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.4.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.5.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.6.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.7.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.8.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.9.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.10.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.11.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.12.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.13.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.14.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.15.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.16.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.17.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.18.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.19.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.20.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.21.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.22.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.23.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.24.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.25.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.26.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.27.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.28.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.29.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.30.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.31.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.32.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.33.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.34.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.35.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.36.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'joint_blocks.37.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'final_layer.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'model1',
        'model2',
        'pos_embed.',
        'x_embedder.',
        'context_embedder.',
        'y_embedder.',
        't_embedder.',
        'joint_blocks.0.',
        'joint_blocks.1.',
        'joint_blocks.2.',
        'joint_blocks.3.',
        'joint_blocks.4.',
        'joint_blocks.5.',
        'joint_blocks.6.',
        'joint_blocks.7.',
        'joint_blocks.8.',
        'joint_blocks.9.',
        'joint_blocks.10.',
        'joint_blocks.11.',
        'joint_blocks.12.',
        'joint_blocks.13.',
        'joint_blocks.14.',
        'joint_blocks.15.',
        'joint_blocks.16.',
        'joint_blocks.17.',
        'joint_blocks.18.',
        'joint_blocks.19.',
        'joint_blocks.20.',
        'joint_blocks.21.',
        'joint_blocks.22.',
        'joint_blocks.23.',
        'joint_blocks.24.',
        'joint_blocks.25.',
        'joint_blocks.26.',
        'joint_blocks.27.',
        'joint_blocks.28.',
        'joint_blocks.29.',
        'joint_blocks.30.',
        'joint_blocks.31.',
        'joint_blocks.32.',
        'joint_blocks.33.',
        'joint_blocks.34.',
        'joint_blocks.35.',
        'joint_blocks.36.',
        'joint_blocks.37.',
        'final_layer.'
      ]
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelMergeSD35_Large',
    display_name: 'ModelMergeSD35_Large',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging_model_specific',
    category: 'advanced/model_merging/model_specific',
    output_node: false
  },
  ModelMergeMochiPreview: {
    input: {
      required: {
        model1: ['MODEL'],
        model2: ['MODEL'],
        'pos_frequencies.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        't_embedder.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        't5_y_embedder.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        't5_yproj.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.3.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.4.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.5.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.6.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.7.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.8.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.9.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.10.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.11.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.12.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.13.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.14.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.15.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.16.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.17.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.18.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.19.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.20.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.21.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.22.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.23.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.24.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.25.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.26.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.27.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.28.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.29.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.30.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.31.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.32.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.33.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.34.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.35.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.36.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.37.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.38.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.39.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.40.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.41.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.42.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.43.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.44.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.45.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.46.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'blocks.47.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'final_layer.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'model1',
        'model2',
        'pos_frequencies.',
        't_embedder.',
        't5_y_embedder.',
        't5_yproj.',
        'blocks.0.',
        'blocks.1.',
        'blocks.2.',
        'blocks.3.',
        'blocks.4.',
        'blocks.5.',
        'blocks.6.',
        'blocks.7.',
        'blocks.8.',
        'blocks.9.',
        'blocks.10.',
        'blocks.11.',
        'blocks.12.',
        'blocks.13.',
        'blocks.14.',
        'blocks.15.',
        'blocks.16.',
        'blocks.17.',
        'blocks.18.',
        'blocks.19.',
        'blocks.20.',
        'blocks.21.',
        'blocks.22.',
        'blocks.23.',
        'blocks.24.',
        'blocks.25.',
        'blocks.26.',
        'blocks.27.',
        'blocks.28.',
        'blocks.29.',
        'blocks.30.',
        'blocks.31.',
        'blocks.32.',
        'blocks.33.',
        'blocks.34.',
        'blocks.35.',
        'blocks.36.',
        'blocks.37.',
        'blocks.38.',
        'blocks.39.',
        'blocks.40.',
        'blocks.41.',
        'blocks.42.',
        'blocks.43.',
        'blocks.44.',
        'blocks.45.',
        'blocks.46.',
        'blocks.47.',
        'final_layer.'
      ]
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelMergeMochiPreview',
    display_name: 'ModelMergeMochiPreview',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging_model_specific',
    category: 'advanced/model_merging/model_specific',
    output_node: false
  },
  ModelMergeLTXV: {
    input: {
      required: {
        model1: ['MODEL'],
        model2: ['MODEL'],
        'patchify_proj.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'adaln_single.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'caption_projection.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.0.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.1.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.2.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.3.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.4.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.5.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.6.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.7.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.8.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.9.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.10.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.11.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.12.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.13.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.14.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.15.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.16.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.17.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.18.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.19.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.20.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.21.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.22.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.23.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.24.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.25.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.26.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'transformer_blocks.27.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        scale_shift_table: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ],
        'proj_out.': [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'model1',
        'model2',
        'patchify_proj.',
        'adaln_single.',
        'caption_projection.',
        'transformer_blocks.0.',
        'transformer_blocks.1.',
        'transformer_blocks.2.',
        'transformer_blocks.3.',
        'transformer_blocks.4.',
        'transformer_blocks.5.',
        'transformer_blocks.6.',
        'transformer_blocks.7.',
        'transformer_blocks.8.',
        'transformer_blocks.9.',
        'transformer_blocks.10.',
        'transformer_blocks.11.',
        'transformer_blocks.12.',
        'transformer_blocks.13.',
        'transformer_blocks.14.',
        'transformer_blocks.15.',
        'transformer_blocks.16.',
        'transformer_blocks.17.',
        'transformer_blocks.18.',
        'transformer_blocks.19.',
        'transformer_blocks.20.',
        'transformer_blocks.21.',
        'transformer_blocks.22.',
        'transformer_blocks.23.',
        'transformer_blocks.24.',
        'transformer_blocks.25.',
        'transformer_blocks.26.',
        'transformer_blocks.27.',
        'scale_shift_table',
        'proj_out.'
      ]
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelMergeLTXV',
    display_name: 'ModelMergeLTXV',
    description: '',
    python_module: 'comfy_extras.nodes_model_merging_model_specific',
    category: 'advanced/model_merging/model_specific',
    output_node: false
  },
  PerturbedAttentionGuidance: {
    input: {
      required: {
        model: ['MODEL'],
        scale: [
          'FLOAT',
          {
            default: 3,
            min: 0,
            max: 100,
            step: 0.01,
            round: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'scale']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'PerturbedAttentionGuidance',
    display_name: 'PerturbedAttentionGuidance',
    description: '',
    python_module: 'comfy_extras.nodes_pag',
    category: 'model_patches/unet',
    output_node: false
  },
  AlignYourStepsScheduler: {
    input: {
      required: {
        model_type: [['SD1', 'SDXL', 'SVD']],
        steps: [
          'INT',
          {
            default: 10,
            min: 10,
            max: 10000
          }
        ],
        denoise: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model_type', 'steps', 'denoise']
    },
    output: ['SIGMAS'],
    output_is_list: [false],
    output_name: ['SIGMAS'],
    name: 'AlignYourStepsScheduler',
    display_name: 'AlignYourStepsScheduler',
    description: '',
    python_module: 'comfy_extras.nodes_align_your_steps',
    category: 'sampling/custom_sampling/schedulers',
    output_node: false
  },
  UNetSelfAttentionMultiply: {
    input: {
      required: {
        model: ['MODEL'],
        q: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        k: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        v: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        out: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'q', 'k', 'v', 'out']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'UNetSelfAttentionMultiply',
    display_name: 'UNetSelfAttentionMultiply',
    description: '',
    python_module: 'comfy_extras.nodes_attention_multiply',
    category: '_for_testing/attention_experiments',
    output_node: false
  },
  UNetCrossAttentionMultiply: {
    input: {
      required: {
        model: ['MODEL'],
        q: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        k: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        v: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        out: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'q', 'k', 'v', 'out']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'UNetCrossAttentionMultiply',
    display_name: 'UNetCrossAttentionMultiply',
    description: '',
    python_module: 'comfy_extras.nodes_attention_multiply',
    category: '_for_testing/attention_experiments',
    output_node: false
  },
  CLIPAttentionMultiply: {
    input: {
      required: {
        clip: ['CLIP'],
        q: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        k: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        v: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        out: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['clip', 'q', 'k', 'v', 'out']
    },
    output: ['CLIP'],
    output_is_list: [false],
    output_name: ['CLIP'],
    name: 'CLIPAttentionMultiply',
    display_name: 'CLIPAttentionMultiply',
    description: '',
    python_module: 'comfy_extras.nodes_attention_multiply',
    category: '_for_testing/attention_experiments',
    output_node: false
  },
  UNetTemporalAttentionMultiply: {
    input: {
      required: {
        model: ['MODEL'],
        self_structural: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        self_temporal: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        cross_structural: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        cross_temporal: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: [
        'model',
        'self_structural',
        'self_temporal',
        'cross_structural',
        'cross_temporal'
      ]
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'UNetTemporalAttentionMultiply',
    display_name: 'UNetTemporalAttentionMultiply',
    description: '',
    python_module: 'comfy_extras.nodes_attention_multiply',
    category: '_for_testing/attention_experiments',
    output_node: false
  },
  SamplerLCMUpscale: {
    input: {
      required: {
        scale_ratio: [
          'FLOAT',
          {
            default: 1,
            min: 0.1,
            max: 20,
            step: 0.01
          }
        ],
        scale_steps: [
          'INT',
          {
            default: -1,
            min: -1,
            max: 1000,
            step: 1
          }
        ],
        upscale_method: [
          ['bislerp', 'nearest-exact', 'bilinear', 'area', 'bicubic']
        ]
      }
    },
    input_order: {
      required: ['scale_ratio', 'scale_steps', 'upscale_method']
    },
    output: ['SAMPLER'],
    output_is_list: [false],
    output_name: ['SAMPLER'],
    name: 'SamplerLCMUpscale',
    display_name: 'SamplerLCMUpscale',
    description: '',
    python_module: 'comfy_extras.nodes_advanced_samplers',
    category: 'sampling/custom_sampling/samplers',
    output_node: false
  },
  SamplerEulerCFGpp: {
    input: {
      required: {
        version: [['regular', 'alternative']]
      }
    },
    input_order: {
      required: ['version']
    },
    output: ['SAMPLER'],
    output_is_list: [false],
    output_name: ['SAMPLER'],
    name: 'SamplerEulerCFGpp',
    display_name: 'SamplerEulerCFG++',
    description: '',
    python_module: 'comfy_extras.nodes_advanced_samplers',
    category: '_for_testing',
    output_node: false
  },
  WebcamCapture: {
    input: {
      required: {
        image: ['WEBCAM', {}],
        width: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        height: [
          'INT',
          {
            default: 0,
            min: 0,
            max: 16384,
            step: 1
          }
        ],
        capture_on_queue: [
          'BOOLEAN',
          {
            default: true
          }
        ]
      }
    },
    input_order: {
      required: ['image', 'width', 'height', 'capture_on_queue']
    },
    output: ['IMAGE'],
    output_is_list: [false],
    output_name: ['IMAGE'],
    name: 'WebcamCapture',
    display_name: 'Webcam Capture',
    description: '',
    python_module: 'comfy_extras.nodes_webcam',
    category: 'image',
    output_node: false
  },
  EmptyLatentAudio: {
    input: {
      required: {
        seconds: [
          'FLOAT',
          {
            default: 47.6,
            min: 1,
            max: 1000,
            step: 0.1
          }
        ],
        batch_size: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096,
            tooltip: 'The number of latent images in the batch.'
          }
        ]
      }
    },
    input_order: {
      required: ['seconds', 'batch_size']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'EmptyLatentAudio',
    display_name: 'EmptyLatentAudio',
    description: '',
    python_module: 'comfy_extras.nodes_audio',
    category: 'latent/audio',
    output_node: false
  },
  VAEEncodeAudio: {
    input: {
      required: {
        audio: ['AUDIO'],
        vae: ['VAE']
      }
    },
    input_order: {
      required: ['audio', 'vae']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'VAEEncodeAudio',
    display_name: 'VAEEncodeAudio',
    description: '',
    python_module: 'comfy_extras.nodes_audio',
    category: 'latent/audio',
    output_node: false
  },
  VAEDecodeAudio: {
    input: {
      required: {
        samples: ['LATENT'],
        vae: ['VAE']
      }
    },
    input_order: {
      required: ['samples', 'vae']
    },
    output: ['AUDIO'],
    output_is_list: [false],
    output_name: ['AUDIO'],
    name: 'VAEDecodeAudio',
    display_name: 'VAEDecodeAudio',
    description: '',
    python_module: 'comfy_extras.nodes_audio',
    category: 'latent/audio',
    output_node: false
  },
  SaveAudio: {
    input: {
      required: {
        audio: ['AUDIO'],
        filename_prefix: [
          'STRING',
          {
            default: 'audio/ComfyUI'
          }
        ],
        audioUI: ['AUDIO_UI']
      },
      hidden: {
        prompt: 'PROMPT',
        extra_pnginfo: 'EXTRA_PNGINFO'
      }
    },
    input_order: {
      required: ['audio', 'filename_prefix'],
      hidden: ['prompt', 'extra_pnginfo']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'SaveAudio',
    display_name: 'SaveAudio',
    description: '',
    python_module: 'comfy_extras.nodes_audio',
    category: 'audio',
    output_node: true
  },
  LoadAudio: {
    input: {
      required: {
        audio: [
          [],
          {
            audio_upload: true
          }
        ],
        audioUI: ['AUDIO_UI'],
        upload: ['AUDIOUPLOAD']
      }
    },
    input_order: {
      required: ['audio']
    },
    output: ['AUDIO'],
    output_is_list: [false],
    output_name: ['AUDIO'],
    name: 'LoadAudio',
    display_name: 'LoadAudio',
    description: '',
    python_module: 'comfy_extras.nodes_audio',
    category: 'audio',
    output_node: false
  },
  PreviewAudio: {
    input: {
      required: {
        audio: ['AUDIO'],
        audioUI: ['AUDIO_UI']
      },
      hidden: {
        prompt: 'PROMPT',
        extra_pnginfo: 'EXTRA_PNGINFO'
      }
    },
    input_order: {
      required: ['audio'],
      hidden: ['prompt', 'extra_pnginfo']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'PreviewAudio',
    display_name: 'PreviewAudio',
    description: '',
    python_module: 'comfy_extras.nodes_audio',
    category: 'audio',
    output_node: true
  },
  TripleCLIPLoader: {
    input: {
      required: {
        clip_name1: [[]],
        clip_name2: [[]],
        clip_name3: [[]]
      }
    },
    input_order: {
      required: ['clip_name1', 'clip_name2', 'clip_name3']
    },
    output: ['CLIP'],
    output_is_list: [false],
    output_name: ['CLIP'],
    name: 'TripleCLIPLoader',
    display_name: 'TripleCLIPLoader',
    description: '[Recipes]\n\nsd3: clip-l, clip-g, t5',
    python_module: 'comfy_extras.nodes_sd3',
    category: 'advanced/loaders',
    output_node: false
  },
  EmptySD3LatentImage: {
    input: {
      required: {
        width: [
          'INT',
          {
            default: 1024,
            min: 16,
            max: 16384,
            step: 16
          }
        ],
        height: [
          'INT',
          {
            default: 1024,
            min: 16,
            max: 16384,
            step: 16
          }
        ],
        batch_size: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096
          }
        ]
      }
    },
    input_order: {
      required: ['width', 'height', 'batch_size']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'EmptySD3LatentImage',
    display_name: 'EmptySD3LatentImage',
    description: '',
    python_module: 'comfy_extras.nodes_sd3',
    category: 'latent/sd3',
    output_node: false
  },
  CLIPTextEncodeSD3: {
    input: {
      required: {
        clip: ['CLIP'],
        clip_l: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true
          }
        ],
        clip_g: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true
          }
        ],
        t5xxl: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true
          }
        ],
        empty_padding: [['none', 'empty_prompt']]
      }
    },
    input_order: {
      required: ['clip', 'clip_l', 'clip_g', 't5xxl', 'empty_padding']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'CLIPTextEncodeSD3',
    display_name: 'CLIPTextEncodeSD3',
    description: '',
    python_module: 'comfy_extras.nodes_sd3',
    category: 'advanced/conditioning',
    output_node: false
  },
  ControlNetApplySD3: {
    input: {
      required: {
        positive: ['CONDITIONING'],
        negative: ['CONDITIONING'],
        control_net: ['CONTROL_NET'],
        vae: ['VAE'],
        image: ['IMAGE'],
        strength: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        start_percent: [
          'FLOAT',
          {
            default: 0,
            min: 0,
            max: 1,
            step: 0.001
          }
        ],
        end_percent: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.001
          }
        ]
      }
    },
    input_order: {
      required: [
        'positive',
        'negative',
        'control_net',
        'vae',
        'image',
        'strength',
        'start_percent',
        'end_percent'
      ]
    },
    output: ['CONDITIONING', 'CONDITIONING'],
    output_is_list: [false, false],
    output_name: ['positive', 'negative'],
    name: 'ControlNetApplySD3',
    display_name: 'Apply Controlnet with VAE',
    description: '',
    python_module: 'comfy_extras.nodes_sd3',
    category: 'conditioning/controlnet',
    output_node: false,
    deprecated: true
  },
  SkipLayerGuidanceSD3: {
    input: {
      required: {
        model: ['MODEL'],
        layers: [
          'STRING',
          {
            default: '7, 8, 9',
            multiline: false
          }
        ],
        scale: [
          'FLOAT',
          {
            default: 3,
            min: 0,
            max: 10,
            step: 0.1
          }
        ],
        start_percent: [
          'FLOAT',
          {
            default: 0.01,
            min: 0,
            max: 1,
            step: 0.001
          }
        ],
        end_percent: [
          'FLOAT',
          {
            default: 0.15,
            min: 0,
            max: 1,
            step: 0.001
          }
        ]
      }
    },
    input_order: {
      required: ['model', 'layers', 'scale', 'start_percent', 'end_percent']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'SkipLayerGuidanceSD3',
    display_name: 'SkipLayerGuidanceSD3',
    description:
      'Generic version of SkipLayerGuidance node that can be used on every DiT model.',
    python_module: 'comfy_extras.nodes_sd3',
    category: 'advanced/guidance',
    output_node: false,
    experimental: true
  },
  GITSScheduler: {
    input: {
      required: {
        coeff: [
          'FLOAT',
          {
            default: 1.2,
            min: 0.8,
            max: 1.5,
            step: 0.05
          }
        ],
        steps: [
          'INT',
          {
            default: 10,
            min: 2,
            max: 1000
          }
        ],
        denoise: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['coeff', 'steps', 'denoise']
    },
    output: ['SIGMAS'],
    output_is_list: [false],
    output_name: ['SIGMAS'],
    name: 'GITSScheduler',
    display_name: 'GITSScheduler',
    description: '',
    python_module: 'comfy_extras.nodes_gits',
    category: 'sampling/custom_sampling/schedulers',
    output_node: false
  },
  SetUnionControlNetType: {
    input: {
      required: {
        control_net: ['CONTROL_NET'],
        type: [
          [
            'auto',
            'openpose',
            'depth',
            'hed/pidi/scribble/ted',
            'canny/lineart/anime_lineart/mlsd',
            'normal',
            'segment',
            'tile',
            'repaint'
          ]
        ]
      }
    },
    input_order: {
      required: ['control_net', 'type']
    },
    output: ['CONTROL_NET'],
    output_is_list: [false],
    output_name: ['CONTROL_NET'],
    name: 'SetUnionControlNetType',
    display_name: 'SetUnionControlNetType',
    description: '',
    python_module: 'comfy_extras.nodes_controlnet',
    category: 'conditioning/controlnet',
    output_node: false
  },
  ControlNetInpaintingAliMamaApply: {
    input: {
      required: {
        positive: ['CONDITIONING'],
        negative: ['CONDITIONING'],
        control_net: ['CONTROL_NET'],
        vae: ['VAE'],
        image: ['IMAGE'],
        mask: ['MASK'],
        strength: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 10,
            step: 0.01
          }
        ],
        start_percent: [
          'FLOAT',
          {
            default: 0,
            min: 0,
            max: 1,
            step: 0.001
          }
        ],
        end_percent: [
          'FLOAT',
          {
            default: 1,
            min: 0,
            max: 1,
            step: 0.001
          }
        ]
      }
    },
    input_order: {
      required: [
        'positive',
        'negative',
        'control_net',
        'vae',
        'image',
        'mask',
        'strength',
        'start_percent',
        'end_percent'
      ]
    },
    output: ['CONDITIONING', 'CONDITIONING'],
    output_is_list: [false, false],
    output_name: ['positive', 'negative'],
    name: 'ControlNetInpaintingAliMamaApply',
    display_name: 'ControlNetInpaintingAliMamaApply',
    description: '',
    python_module: 'comfy_extras.nodes_controlnet',
    category: 'conditioning/controlnet',
    output_node: false
  },
  CLIPTextEncodeHunyuanDiT: {
    input: {
      required: {
        clip: ['CLIP'],
        bert: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true
          }
        ],
        mt5xl: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true
          }
        ]
      }
    },
    input_order: {
      required: ['clip', 'bert', 'mt5xl']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'CLIPTextEncodeHunyuanDiT',
    display_name: 'CLIPTextEncodeHunyuanDiT',
    description: '',
    python_module: 'comfy_extras.nodes_hunyuan',
    category: 'advanced/conditioning',
    output_node: false
  },
  CLIPTextEncodeFlux: {
    input: {
      required: {
        clip: ['CLIP'],
        clip_l: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true
          }
        ],
        t5xxl: [
          'STRING',
          {
            multiline: true,
            dynamicPrompts: true
          }
        ],
        guidance: [
          'FLOAT',
          {
            default: 3.5,
            min: 0,
            max: 100,
            step: 0.1
          }
        ]
      }
    },
    input_order: {
      required: ['clip', 'clip_l', 't5xxl', 'guidance']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'CLIPTextEncodeFlux',
    display_name: 'CLIPTextEncodeFlux',
    description: '',
    python_module: 'comfy_extras.nodes_flux',
    category: 'advanced/conditioning/flux',
    output_node: false
  },
  FluxGuidance: {
    input: {
      required: {
        conditioning: ['CONDITIONING'],
        guidance: [
          'FLOAT',
          {
            default: 3.5,
            min: 0,
            max: 100,
            step: 0.1
          }
        ]
      }
    },
    input_order: {
      required: ['conditioning', 'guidance']
    },
    output: ['CONDITIONING'],
    output_is_list: [false],
    output_name: ['CONDITIONING'],
    name: 'FluxGuidance',
    display_name: 'FluxGuidance',
    description: '',
    python_module: 'comfy_extras.nodes_flux',
    category: 'advanced/conditioning/flux',
    output_node: false
  },
  LoraSave: {
    input: {
      required: {
        filename_prefix: [
          'STRING',
          {
            default: 'loras/ComfyUI_extracted_lora'
          }
        ],
        rank: [
          'INT',
          {
            default: 8,
            min: 1,
            max: 4096,
            step: 1
          }
        ],
        lora_type: [['standard', 'full_diff']],
        bias_diff: [
          'BOOLEAN',
          {
            default: true
          }
        ]
      },
      optional: {
        model_diff: [
          'MODEL',
          {
            tooltip: 'The ModelSubtract output to be converted to a lora.'
          }
        ],
        text_encoder_diff: [
          'CLIP',
          {
            tooltip: 'The CLIPSubtract output to be converted to a lora.'
          }
        ]
      }
    },
    input_order: {
      required: ['filename_prefix', 'rank', 'lora_type', 'bias_diff'],
      optional: ['model_diff', 'text_encoder_diff']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'LoraSave',
    display_name: 'Extract and Save Lora',
    description: '',
    python_module: 'comfy_extras.nodes_lora_extract',
    category: '_for_testing',
    output_node: true
  },
  TorchCompileModel: {
    input: {
      required: {
        model: ['MODEL'],
        backend: [['inductor', 'cudagraphs']]
      }
    },
    input_order: {
      required: ['model', 'backend']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'TorchCompileModel',
    display_name: 'TorchCompileModel',
    description: '',
    python_module: 'comfy_extras.nodes_torch_compile',
    category: '_for_testing',
    output_node: false,
    experimental: true
  },
  EmptyMochiLatentVideo: {
    input: {
      required: {
        width: [
          'INT',
          {
            default: 848,
            min: 16,
            max: 16384,
            step: 16
          }
        ],
        height: [
          'INT',
          {
            default: 480,
            min: 16,
            max: 16384,
            step: 16
          }
        ],
        length: [
          'INT',
          {
            default: 25,
            min: 7,
            max: 16384,
            step: 6
          }
        ],
        batch_size: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096
          }
        ]
      }
    },
    input_order: {
      required: ['width', 'height', 'length', 'batch_size']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'EmptyMochiLatentVideo',
    display_name: 'EmptyMochiLatentVideo',
    description: '',
    python_module: 'comfy_extras.nodes_mochi',
    category: 'latent/video',
    output_node: false
  },
  SkipLayerGuidanceDiT: {
    input: {
      required: {
        model: ['MODEL'],
        double_layers: [
          'STRING',
          {
            default: '7, 8, 9',
            multiline: false
          }
        ],
        single_layers: [
          'STRING',
          {
            default: '7, 8, 9',
            multiline: false
          }
        ],
        scale: [
          'FLOAT',
          {
            default: 3,
            min: 0,
            max: 10,
            step: 0.1
          }
        ],
        start_percent: [
          'FLOAT',
          {
            default: 0.01,
            min: 0,
            max: 1,
            step: 0.001
          }
        ],
        end_percent: [
          'FLOAT',
          {
            default: 0.15,
            min: 0,
            max: 1,
            step: 0.001
          }
        ]
      }
    },
    input_order: {
      required: [
        'model',
        'double_layers',
        'single_layers',
        'scale',
        'start_percent',
        'end_percent'
      ]
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'SkipLayerGuidanceDiT',
    display_name: 'SkipLayerGuidanceDiT',
    description:
      'Generic version of SkipLayerGuidance node that can be used on every DiT model.',
    python_module: 'comfy_extras.nodes_slg',
    category: 'advanced/guidance',
    output_node: false,
    experimental: true
  },
  EmptyLTXVLatentVideo: {
    input: {
      required: {
        width: [
          'INT',
          {
            default: 768,
            min: 64,
            max: 16384,
            step: 32
          }
        ],
        height: [
          'INT',
          {
            default: 512,
            min: 64,
            max: 16384,
            step: 32
          }
        ],
        length: [
          'INT',
          {
            default: 97,
            min: 1,
            max: 16384,
            step: 8
          }
        ],
        batch_size: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096
          }
        ]
      }
    },
    input_order: {
      required: ['width', 'height', 'length', 'batch_size']
    },
    output: ['LATENT'],
    output_is_list: [false],
    output_name: ['LATENT'],
    name: 'EmptyLTXVLatentVideo',
    display_name: 'EmptyLTXVLatentVideo',
    description: '',
    python_module: 'comfy_extras.nodes_lt',
    category: 'latent/video/ltxv',
    output_node: false
  },
  LTXVImgToVideo: {
    input: {
      required: {
        positive: ['CONDITIONING'],
        negative: ['CONDITIONING'],
        vae: ['VAE'],
        image: ['IMAGE'],
        width: [
          'INT',
          {
            default: 768,
            min: 64,
            max: 16384,
            step: 32
          }
        ],
        height: [
          'INT',
          {
            default: 512,
            min: 64,
            max: 16384,
            step: 32
          }
        ],
        length: [
          'INT',
          {
            default: 97,
            min: 9,
            max: 16384,
            step: 8
          }
        ],
        batch_size: [
          'INT',
          {
            default: 1,
            min: 1,
            max: 4096
          }
        ]
      }
    },
    input_order: {
      required: [
        'positive',
        'negative',
        'vae',
        'image',
        'width',
        'height',
        'length',
        'batch_size'
      ]
    },
    output: ['CONDITIONING', 'CONDITIONING', 'LATENT'],
    output_is_list: [false, false, false],
    output_name: ['positive', 'negative', 'latent'],
    name: 'LTXVImgToVideo',
    display_name: 'LTXVImgToVideo',
    description: '',
    python_module: 'comfy_extras.nodes_lt',
    category: 'conditioning/video_models',
    output_node: false
  },
  ModelSamplingLTXV: {
    input: {
      required: {
        model: ['MODEL'],
        max_shift: [
          'FLOAT',
          {
            default: 2.05,
            min: 0,
            max: 100,
            step: 0.01
          }
        ],
        base_shift: [
          'FLOAT',
          {
            default: 0.95,
            min: 0,
            max: 100,
            step: 0.01
          }
        ]
      },
      optional: {
        latent: ['LATENT']
      }
    },
    input_order: {
      required: ['model', 'max_shift', 'base_shift'],
      optional: ['latent']
    },
    output: ['MODEL'],
    output_is_list: [false],
    output_name: ['MODEL'],
    name: 'ModelSamplingLTXV',
    display_name: 'ModelSamplingLTXV',
    description: '',
    python_module: 'comfy_extras.nodes_lt',
    category: 'advanced/model',
    output_node: false
  },
  LTXVConditioning: {
    input: {
      required: {
        positive: ['CONDITIONING'],
        negative: ['CONDITIONING'],
        frame_rate: [
          'FLOAT',
          {
            default: 25,
            min: 0,
            max: 1000,
            step: 0.01
          }
        ]
      }
    },
    input_order: {
      required: ['positive', 'negative', 'frame_rate']
    },
    output: ['CONDITIONING', 'CONDITIONING'],
    output_is_list: [false, false],
    output_name: ['positive', 'negative'],
    name: 'LTXVConditioning',
    display_name: 'LTXVConditioning',
    description: '',
    python_module: 'comfy_extras.nodes_lt',
    category: 'conditioning/video_models',
    output_node: false
  },
  LTXVScheduler: {
    input: {
      required: {
        steps: [
          'INT',
          {
            default: 20,
            min: 1,
            max: 10000
          }
        ],
        max_shift: [
          'FLOAT',
          {
            default: 2.05,
            min: 0,
            max: 100,
            step: 0.01
          }
        ],
        base_shift: [
          'FLOAT',
          {
            default: 0.95,
            min: 0,
            max: 100,
            step: 0.01
          }
        ],
        stretch: [
          'BOOLEAN',
          {
            default: true,
            tooltip: 'Stretch the sigmas to be in the range [terminal, 1].'
          }
        ],
        terminal: [
          'FLOAT',
          {
            default: 0.1,
            min: 0,
            max: 0.99,
            step: 0.01,
            tooltip: 'The terminal value of the sigmas after stretching.'
          }
        ]
      },
      optional: {
        latent: ['LATENT']
      }
    },
    input_order: {
      required: ['steps', 'max_shift', 'base_shift', 'stretch', 'terminal'],
      optional: ['latent']
    },
    output: ['SIGMAS'],
    output_is_list: [false],
    output_name: ['SIGMAS'],
    name: 'LTXVScheduler',
    display_name: 'LTXVScheduler',
    description: '',
    python_module: 'comfy_extras.nodes_lt',
    category: 'sampling/custom_sampling/schedulers',
    output_node: false
  },
  SaveImageWebsocket: {
    input: {
      required: {
        images: ['IMAGE']
      }
    },
    input_order: {
      required: ['images']
    },
    output: [],
    output_is_list: [],
    output_name: [],
    name: 'SaveImageWebsocket',
    display_name: 'SaveImageWebsocket',
    description: '',
    python_module: 'custom_nodes.websocket_image_save',
    category: 'api/image',
    output_node: true
  }
}

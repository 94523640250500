<!-- A message that displays the current user -->
<template>
  <Message
    v-if="userStore.isMultiUserServer"
    severity="info"
    icon="pi pi-user"
    pt:text="w-full"
  >
    <div class="flex items-center justify-between">
      <div>{{ $t('currentUser') }}: {{ userStore.currentUser?.username }}</div>
      <Button icon="pi pi-sign-out" @click="logout" text />
    </div>
  </Message>
</template>

<script setup lang="ts">
import Message from 'primevue/message'
import Button from 'primevue/button'
import { useUserStore } from '@/stores/userStore'

const userStore = useUserStore()
const logout = () => {
  userStore.logout()
  window.location.reload()
}
</script>
